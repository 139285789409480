import React, { Suspense, useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { SnackbarDTB } from "components/common";
import { MaintenancePage } from "components/pages";
import { ConnectedRouter } from "connected-react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AuthProvider, AuthService } from "react-oauth2-pkce";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "routes";
import configureStore, { history } from "store";
import theme from "styles/theme";
import "./App.css";
import { uiControlStore } from "store/store";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const authService = new AuthService({
    clientId: process.env.REACT_APP_CLIENT_ID,
    location: window.location,
    authorizeEndpoint: process.env.REACT_APP_FED_CODE_URL,
    tokenEndpoint: process.env.REACT_APP_FED_TOKEN_URL,
    provider: process.env.REACT_APP_FED_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ["openid", "profile", "email"]
});

const helmetContext = {};
// Take the endpoint URL & removing the /api
const connectSrcCSP = process.env.REACT_APP_API_ENDPOINT.slice(0, -4);
const storageBucketCSP = process.env.REACT_APP_STORAGE_BUCKET_URL;
const fedUrlCSP = process.env.REACT_APP_FED_REVOKE_URL;
const CSP = `connect-src 'self' ${connectSrcCSP} ${fedUrlCSP}; default-src 'self'; img-src 'self' data: blob: ${storageBucketCSP}; manifest-src 'self'; script-src-elem 'self'; frame-src https://www.youtube.com/ https://gateway.zscaler.net/ ; style-src 'self' fonts.googleapis.com 'unsafe-inline'; font-src 'self' fonts.gstatic.com`;

const { store, persistor } = configureStore();
const persister = createSyncStoragePersister({
    storage: window.localStorage
});

const App = () => {
    const { languageDirection } = uiControlStore(state => ({
        languageDirection: state.languageDirection
    }));

    useEffect(() => {
        document.getElementsByTagName("html")[0].dir = languageDirection;
    }, [languageDirection]);

    if (process.env.REACT_APP_MAINTENANCE_MODE == "1") return <MaintenancePage />;
    return (
        <HelmetProvider context={helmetContext}>
            <Helmet>
                <title>Decathlon Teammates Barometer</title>
                <meta http-equiv="Content-Security-Policy" content={CSP} />
            </Helmet>
            <Suspense>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <PersistQueryClientProvider
                            client={queryClient}
                            persistOptions={{ persister }}>
                            <ThemeProvider theme={theme}>
                                <ConnectedRouter history={history}>
                                    <AuthProvider authService={authService}>
                                        <div dir={languageDirection} style={{ height: "100%" }}>
                                            <Routes />
                                            <SnackbarDTB />
                                        </div>
                                    </AuthProvider>
                                </ConnectedRouter>
                            </ThemeProvider>
                        </PersistQueryClientProvider>
                    </PersistGate>
                </Provider>
            </Suspense>
        </HelmetProvider>
    );
};

export default App;
