export const getSurveyFinish = () => ({
    method: "GET",
    suffix: `/surveys/completed`
});

export const getCompilationParameters = () => ({
    method: "POST",
    suffix: `/surveys/compilations_parameters`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getExportExcel = () => ({
    method: "POST",
    suffix: `/surveys/compilations_results?export=true`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getCompilationResultPleasure = () => ({
    method: "POST",
    suffix: `/surveys/compilations_results?pleasure=true`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getCompilationResultThematic = () => ({
    method: "POST",
    suffix: `/surveys/compilations_results?thematic=true`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getCompilationResultQuestion = () => ({
    method: "POST",
    suffix: `/surveys/compilations_results?question=true`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getCompilationResultCountryRate = () => ({
    method: "POST",
    suffix: `/surveys/compilations_results?countryRate=true`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getCompilationResultFreeQuestion = () => ({
    method: "POST",
    suffix: `/surveys/compilations_results?freeQuestion=true`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const getCompliationsResultCheckTarget = () => ({
    method: "POST",
    suffix: `/surveys/compilations_results?validUid=true`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});
