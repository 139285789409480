import "@fontsource-variable/inter";
import { alpha, createTheme } from "@mui/material";
import colors from "./colors";

const GLOBAL_SHAPE = 10;

const theme = createTheme({
    shape: {
        borderRadius: GLOBAL_SHAPE
    },
    palette: {
        mode: "light",
        primary: {
            main: "#3643BA"
        },
        secondary: {
            main: "#7AFFA6"
        },
        error: {
            main: "#e55039"
        },
        warning: {
            main: "#f6b93b"
        },
        info: {
            main: "#4a69bd"
        },
        success: {
            main: "#78e08f"
        },
        neutral: {
            black: "#101010",
            darkGrey: "#616161",
            lightGrey: "#949494",
            whiteGrey: "#F5F4F5", //Background
            white: "#FFFFFF"
        },
        common: {
            ...colors
        }
    },
    typography: {
        fontFamily: "Inter Variable, sans-serif",
        h1: {
            fontSize: "3rem",
            fontWeight: "bold"
        },
        h2: {
            fontSize: "2.5rem",
            fontWeight: "bold"
        },
        h3: {
            fontSize: "2rem",
            fontWeight: "bold"
        },
        h4: {
            fontSize: "1.4rem",
            fontWeight: "bold",
            textTransform: "capitalize",
            margin: "1rem 0"
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: "lighter",
            fontStyle: "italic"
        },
        subtitle2: {
            fontSize: "1rem",
            fontWeight: "bold",
            fontStyle: "italic",
            textTransform: "uppercase"
        }
    },
    animations: {
        heartbeat: {
            animation: "heartbeat 1.5s ease-in-out infinite both",
            "@keyframes heartbeat": {
                from: {
                    transform: "scale(1)",
                    "transform-origin": "center center",
                    "animation-timing-function": "ease-out"
                },
                "10%": {
                    transform: "scale(0.91)",
                    "animation-timing-function": "ease-in"
                },
                "17%": {
                    transform: "scale(0.98)",
                    "animation-timing-function": "ease-out"
                },
                "33%": {
                    transform: "scale(0.87)",
                    "animation-timing-function": "ease-in"
                },
                "45%": {
                    transform: "scale(1)",
                    "animation-timing-function": "ease-out"
                }
            }
        },
        colorChange: {
            animation: "color-change 0.5s linear alternate both",
            "@keyframes color-change": {
                "0%": {
                    backgroundColor: "#FFFFFF" // palette.neutral.white
                },
                "100%": {
                    backgroundColor: "#3643BA" // palette.primary.main
                }
            }
        },
        pulse: {
            animation: "pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1)",
            "@keyframes pulse": { to: { boxShadow: `0 0 0 25px ${alpha("#FFFFFF", 0)}` } },
            "&:hover": { animation: "none" }
        },
        exitFwdCenter: {
            animation: "exit-fwd-center 0.7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both",
            "@keyframes exit-fwd-center": {
                "0%": {
                    transform: "translateZ(1)",
                    opacity: 1
                },
                "100%": {
                    transform: "translateZ(600px)",
                    opacity: 0
                }
            }
        },
        scaleInTopRight: {
            animation: "scaleInTopRight .2s cubic-bezier(.25,.46,.45,.94) both",
            "@keyframes scaleInTopRight": {
                "0%": { transform: "scale(0)", transformOrigin: "100% 0", opacity: 1 },
                "100%": { transform: "scale(1)", transformOrigin: "100% 0", opacity: 1 }
            }
        },
        scaleOutTopRight: {
            animation: "scaleOutTopRight .2s cubic-bezier(.25,.46,.45,.94) both",
            "@keyframes scaleOutTopRight": {
                "0%": { transform: "scale(1)", transformOrigin: "100% 0", opacity: 1 },
                "100%": { transform: "scale(0)", transformOrigin: "100% 0", opacity: 1 }
            }
        },
        scaleInCenter: {
            scaleInCenter: { animation: "scaleInCenter .2s cubic-bezier(.25,.46,.45,.94) both" },
            "@keyframes scaleInCenter": {
                "0%": {
                    transform: "scale(0)",
                    opacity: 1
                },
                "100%": {
                    transform: "scale(1)",
                    opacity: 1
                }
            }
        },
        enterSlideTop: {
            animation: "enter-slide-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
            "@keyframes enter-slide-top": {
                "0%": {
                    "-webkit-transform": "translateY(-1000px)",
                    transform: "translateY(-1000px)",
                    opacity: 0
                },
                "100%": {
                    "-webkit-transform": "translateY(0)",
                    transform: "translateY(0)",
                    opacity: 1
                }
            }
        },
        enterOpacity: {
            animation: "scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
            "@keyframes scale-in-center": {
                "0%": {
                    opacity: 0
                },
                "100%": {
                    opacity: 1
                }
            }
        }
    },
    components: {
        MuiCardActions: {
            styleOverrides: { root: { padding: GLOBAL_SHAPE + "px" } }
        },
        MuiButton: {
            styleOverrides: { contained: { height: "40px" } }
        },
        MuiTableCell: {
            styleOverrides: {
                sizeSmall: {
                    padding: "5px"
                }
            }
        }
    }
});

export default theme;
