import * as TYPES from "./types";
import { fetchData } from "api/fetch";
import {
    getMyTeamBloomingResult,
    getMyTeamResultsByTheme,
    getMyTeamDetailedResults,
    getMyTeamComments,
    getMyTeamParticipationRate,
} from "./endpoints";
import { push } from "connected-react-router";
import { MIN_ANSWERS_RULE, TEAM } from "enums";

export const setSelectedFilter = selected_filter => ({
    type: TYPES.SET_SELECTED_FILTER,
    payload: selected_filter
});

export const setSelectedBoxFilter = selected_box_filter => ({
    type: TYPES.SET_SELECTED_BOX_FILTER,
    payload: selected_box_filter
});

export const fetchPreferredFilter = () => dispatch => {
    dispatch(setSelectedFilter(0));
};

export const setMyResults = results => ({
    type: TYPES.SET_MY_RESULTS,
    payload: results
});

export const setMySurvey = results => ({
    type: TYPES.SET_MY_SURVEY,
    payload: results
});

export const setResults = (results, team_id) => (dispatch, getState) => {
    if (team_id === "N") {
        dispatch(setMyTeamNResults(results));
    } else if (team_id === 1) {
        dispatch(setMyTeam1Results(results));
    } else {
        dispatch(setMyTeamResults(results));
    }
};

export const setMyTeamResults = results => ({
    type: TYPES.SET_MY_TEAM_RESULTS,
    payload: results
});

export const setMyTeamNResults = results => ({
    type: TYPES.SET_MY_TEAM_N_RESULTS,
    payload: results
});

export const setMyTeam1Results = results => ({
    type: TYPES.SET_MY_TEAM_1_RESULTS,
    payload: results
});

export const setMyCountryResults = results => ({
    type: TYPES.SET_MY_COUNTRY_RESULTS,
    payload: results
});

export const setMyCountryResultsUnitedBlooming = results => ({
    type: TYPES.SET_MY_COUNTRY_RESULTS_UNITED_BLOOMING,
    payload: results
});

export const setMyCountryResultsUnitedThematic = results => ({
    type: TYPES.SET_MY_COUNTRY_RESULTS_UNITED_THEMATIC,
    payload: results
});

export const setMyCountryResultsUnitedQuestion = results => ({
    type: TYPES.SET_MY_COUNTRY_RESULTS_UNITED_QUESTION,
    payload: results
});

export const setMyMediaContent = results => ({
    type: TYPES.SET_MY_MEDIA_CONTENT,
    payload: results
});

export const setMyCountryParticipationRate = results => ({
    type: TYPES.SET_MY_COUNTRY_PARTICIPATION_RATE,
    payload: results
});

export const setMyLoader = loader => ({
    type: TYPES.SET_MY_LOADER,
    payload: loader
});

/**
 *  Simple boolean flag for country results is requesting.
 *
 * @param {boolean} boolean
 * @returns
 */
export const setFetchingCountry = boolean => ({
    type: TYPES.SET_FETCHING_COUNTRY,
    payload: boolean
});

/**
 *  Flag for teams results requests.
 *  There 3 types of team results request [0, 1, N].
 *  We can requesting 3 types at the same time so the flag is a number.
 *  When a request is launch, increment the flag. When it will finished, decrement it.
 *
 * @param {number} number
 * @returns
 */
export const setFetchingTeams = number => ({
    type: TYPES.SET_FETCHING_TEAMS,
    payload: number
});

export const setMyVerifyLoader = loader => ({
    type: TYPES.SET_MY_VERIFY_LOADER,
    payload: loader
});

export const setMyVerifyError403Team = verifyError403Team => ({
    type: TYPES.SET_MY_VERIFY_ERROR_403_TEAM,
    payload: verifyError403Team
});

export const setMyVerifyError403TeamOne = verifyError403TeamOne => ({
    type: TYPES.SET_MY_VERIFY_ERROR_403_TEAM_ONE,
    payload: verifyError403TeamOne
});

export const setMyVerifyError403TeamN = verifyError403TeamN => ({
    type: TYPES.SET_MY_VERIFY_ERROR_403_TEAM_N,
    payload: verifyError403TeamN
});

export const setCountryNotAuthorized = countryNotAuthorized => ({
    type: TYPES.SET_COUNTRY_NOT_AUTHORIZED,
    payload: countryNotAuthorized
});

export const setSurveyTitleTranslation = surveyTitleTranslation => ({
    type: TYPES.SET_SURVEY_TITLE_TRANSLATION,
    payload: surveyTitleTranslation
});

export const fetchTeamResults = (survey_id, team_id) => (dispatch, getState) => {
    const results = [];
    dispatch(setFetchingTeams(getState().results.fetchingTeamsResults + 1));
    fetchData(getMyTeamParticipationRate(survey_id, team_id))
        .then(participationRate => {
            results.push(participationRate);
            dispatch(setResults(results, team_id));
            if (participationRate[0].nbrAnswer >= MIN_ANSWERS_RULE) {
                fetchData(getMyTeamResultsByTheme(survey_id, team_id))
                    .then(res => {
                        results.push(res);
                        dispatch(setResults(results, team_id));
                        fetchData(getMyTeamDetailedResults(survey_id, team_id))
                            .then(res => {
                                results.push(res);
                                dispatch(setResults(results, team_id));
                                fetchData(getMyTeamComments(survey_id, team_id))
                                    .then(res => {
                                        results.push(res);
                                        dispatch(setResults(results, team_id));
                                        fetchData(getMyTeamBloomingResult(survey_id, team_id)).then(
                                            res => {
                                                results.push(res);
                                                dispatch(setResults(results, team_id));
                                                if (
                                                    getState().users.my_profile.uid ===
                                                        "MABALL50" &&
                                                    getState().results.verifyLoader.length === 3
                                                ) {
                                                    dispatch(setMyLoader(false));
                                                    dispatch(setMyVerifyLoader([]));
                                                } else if (
                                                    getState().users.my_profile.isBoss &&
                                                    getState().results.verifyLoader.length === 4
                                                ) {
                                                    dispatch(setMyLoader(false));
                                                    dispatch(setMyVerifyLoader([]));
                                                } else if (
                                                    !getState().users.my_profile.isBoss &&
                                                    getState().results.verifyLoader.length === 2
                                                ) {
                                                    dispatch(setMyLoader(false));
                                                    dispatch(setMyVerifyLoader([]));
                                                } else {
                                                    let verifyLoaderState = getState().results
                                                        .verifyLoader;
                                                    verifyLoaderState.push(0);
                                                    dispatch(setMyVerifyLoader(verifyLoaderState));
                                                }
                                            }
                                        );
                                    })
                                    .catch(err => {
                                        if (err.status == 403) {
                                            if (err.team1) {
                                                if (err.team1 === true) {
                                                    dispatch(setMyVerifyError403TeamOne(true));
                                                }
                                            } else if (err.teamN) {
                                                if (err.teamN === false) {
                                                    dispatch(setMyVerifyError403TeamN(true));
                                                }
                                            } else if (err.team0) {
                                                if (err.team0 === true) {
                                                    dispatch(setMyVerifyError403Team(true));
                                                }
                                            } else {
                                                dispatch(setMyVerifyError403TeamOne(false));
                                                dispatch(setMyVerifyError403TeamN(false));
                                                dispatch(setMyVerifyError403Team(false));
                                            }
                                            let verifyLoaderState = getState().results.verifyLoader;
                                            verifyLoaderState.push(0);
                                            dispatch(setMyVerifyLoader(verifyLoaderState));
                                        } else {
                                            console.log(err);
                                            dispatch(push("/error"));
                                        }
                                    });
                            })
                            .catch(err => {
                                if (err.status == 403) {
                                    if (err.team1) {
                                        if (err.team1 === true) {
                                            dispatch(setMyVerifyError403TeamOne(true));
                                        }
                                    } else if (err.teamN) {
                                        if (err.teamN === false) {
                                            dispatch(setMyVerifyError403TeamN(true));
                                        }
                                    } else if (err.team0) {
                                        if (err.team0 === true) {
                                            dispatch(setMyVerifyError403Team(true));
                                        }
                                    } else {
                                        dispatch(setMyVerifyError403TeamOne(false));
                                        dispatch(setMyVerifyError403TeamN(false));
                                        dispatch(setMyVerifyError403Team(false));
                                    }
                                    let verifyLoaderState = getState().results.verifyLoader;
                                    verifyLoaderState.push(0);
                                    dispatch(setMyVerifyLoader(verifyLoaderState));
                                } else {
                                    console.log(err);
                                    dispatch(push("/error"));
                                }
                            });
                    })
                    .catch(err => {
                        if (err.status == 403) {
                            if (err.team1) {
                                if (err.team1 === true) {
                                    dispatch(setMyVerifyError403TeamOne(true));
                                }
                            } else if (err.teamN) {
                                if (err.teamN === false) {
                                    dispatch(setMyVerifyError403TeamN(true));
                                }
                            } else if (err.team0) {
                                if (err.team0 === true) {
                                    dispatch(setMyVerifyError403Team(true));
                                }
                            } else {
                                dispatch(setMyVerifyError403TeamOne(false));
                                dispatch(setMyVerifyError403TeamN(false));
                                dispatch(setMyVerifyError403Team(false));
                            }
                            let verifyLoaderState = getState().results.verifyLoader;
                            verifyLoaderState.push(0);
                            dispatch(setMyVerifyLoader(verifyLoaderState));
                        } else {
                            console.log(err);
                            dispatch(push("/error"));
                        }
                    });
            } else {
                let verifyLoaderState = getState().results.verifyLoader;
                verifyLoaderState.push(0);
                dispatch(setMyVerifyLoader(verifyLoaderState));
            }
        })
        .catch(err => {
            if (err.status == 403) {
                if (err.team1) {
                    if (err.team1 === true) {
                        dispatch(setMyVerifyError403TeamOne(true));
                    }
                } else if (err.teamN) {
                    if (err.teamN === false) {
                        dispatch(setMyVerifyError403TeamN(true));
                    }
                } else if (err.team0) {
                    if (err.team0 === true) {
                        dispatch(setMyVerifyError403Team(true));
                    }
                } else {
                    dispatch(setMyVerifyError403TeamOne(false));
                    dispatch(setMyVerifyError403TeamN(false));
                    dispatch(setMyVerifyError403Team(false));
                }
                let verifyLoaderState = getState().results.verifyLoader;
                verifyLoaderState.push(0);
                dispatch(setMyVerifyLoader(verifyLoaderState));
            } else {
                console.log(err);
                dispatch(push("/error"));
            }
        })
        .finally(dispatch(setFetchingTeams(getState().results.fetchingTeamsResults - 1)));
};

// ===========================================================================
//                      Reworked Redux for Results
// ===========================================================================

export const setSurvey = survey => ({ type: TYPES.SET_SURVEY, payload: survey });
export const setMy = result => ({ type: TYPES.SET_MY, payload: result });
export const setTeamZero = result => ({ type: TYPES.SET_TEAM_0, payload: result });
export const setTeamOne = result => ({ type: TYPES.SET_TEAM_1, payload: result });
export const setTeamN = result => ({ type: TYPES.SET_TEAM_N, payload: result });
export const setCountry = result => ({ type: TYPES.SET_COUNTRY, payload: result });
export const setUnited = result => ({ type: TYPES.SET_UNITED, payload: result });

export const setMyProps = (id, status, props, value) => (dispatch, getState) => {
    let curr = getState().results.my;
    dispatch(setMy({ ...curr, [props]: { id: id, status: status, value: value } }));
};

export const setTeamZeroProps = (id, status, props, value) => (dispatch, getState) => {
    let curr = getState().results.teamZero;
    dispatch(setTeamZero({ ...curr, [props]: { id: id, status: status, value: value } }));
};

export const setTeamOneProps = (id, status, props, value) => (dispatch, getState) => {
    let curr = getState().results.teamOne;
    dispatch(setTeamOne({ ...curr, [props]: { id: id, status: status, value: value } }));
};

export const setTeamNProps = (id, status, props, value) => (dispatch, getState) => {
    let curr = getState().results.teamN;
    dispatch(setTeamN({ ...curr, [props]: { id: id, status: status, value: value } }));
};

export const setTeamProps = (id, status, team, props, value) => dispatch => {
    switch (team) {
        case TEAM.I_AM_IN:
            dispatch(setTeamZeroProps(id, status, props, value));
            break;
        case TEAM.I_AM_MANAGE:
            dispatch(setTeamOneProps(id, status, props, value));
            break;
        case TEAM.TOTAL_PERIMETER:
            dispatch(setTeamNProps(id, status, props, value));
            break;
        default:
            throw new Error("Team requested is not compliant!");
    }
};

export const setCountryProps = (id, status, props, value) => (dispatch, getState) => {
    let curr = getState().results.country;
    dispatch(setCountry({ ...curr, [props]: { id: id, status: status, value: value } }));
};

export const setCountryAuthorize = (id, authorize) => (dispatch, getState) => {
    let curr = getState().results.country;
    dispatch(setCountry({ ...curr, access: { id: id, authorize: authorize } }));
};

export const setUnitedProps = (id, status, props, value) => (dispatch, getState) => {
    let curr = getState().results.united;
    dispatch(setUnited({ ...curr, [props]: { id: id, status: status, value: value } }));
};

export const setUnitedAuthorize = (id, authorize) => (dispatch, getState) => {
    let curr = getState().results.united;
    dispatch(setUnited({ ...curr, access: { id: id, authorize: authorize } }));
};
