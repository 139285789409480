import React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Snackbar, SnackbarContent } from "@mui/material";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { uiControlStore } from "store/store";

const PREFIX = "SnackbarDTB";

const classes = {
    success: `${PREFIX}Success`,
    error: `${PREFIX}Error`,
    info: `${PREFIX}Info`,
    warning: `${PREFIX}Warning`,
    icon: `${PREFIX}Icon`,
    message: `${PREFIX}Message`
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    [`& .${classes.success}`]: {
        backgroundColor: alpha(theme.palette.success.main, 0.4)
    },

    [`& .${classes.error}`]: {
        backgroundColor: alpha(theme.palette.error.main, 0.4)
    },

    [`& .${classes.info}`]: {
        backgroundColor: alpha(theme.palette.info.main, 0.4)
    },

    [`& .${classes.warning}`]: {
        backgroundColor: alpha(theme.palette.warning.main, 0.4)
    },

    [`& .${classes.icon}`]: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },

    [`& .${classes.message}`]: {
        color: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
}));

const TIME_TO_DISPLAY = 5;

const SnackbarDTB = () => {
    const { open = false, message, variant, setOpenSnackbar, setMessageSnackbar } = uiControlStore(
        state => ({
            open: state.openSnackbar,
            message: state.messageSnackbar,
            variant: state.variantSnackbar,
            setOpenSnackbar: state.setOpenSnackbar,
            setMessageSnackbar: state.setMessageSnackbar
        })
    );

    const getVariantClass = variant => {
        let variantClass = "";
        switch (variant) {
            case SNACKBAR_VARIANT_TYPE.ERROR:
                variantClass = classes.error;
                break;
            case SNACKBAR_VARIANT_TYPE.WARNING:
                variantClass = classes.warning;
                break;
            case SNACKBAR_VARIANT_TYPE.SUCCESS:
                variantClass = classes.success;
                break;
            default:
                variantClass = classes.info;
                break;
        }
        return variantClass;
    };

    const onClose = () => {
        setOpenSnackbar(false);
        setMessageSnackbar(null);
    };

    return (
        <StyledSnackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            autoHideDuration={TIME_TO_DISPLAY * 1000}
            onClose={onClose}>
            <SnackbarContent
                className={`${getVariantClass(variant)}`}
                // /!\ Mui Components in the message props is causing error : React.jsx: type is invalid -- expected a string (for built-in components) or a class/function (for composite components) but got: undefined. You likely forgot to export your component from the file it's defined in, or you might have mixed up default and named imports.
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
            />
        </StyledSnackbar>
    );
};

export default SnackbarDTB;
