import { fetchFile, fetchData } from "api/fetch";
import {
    createEmail,
    createMedia,
    deleteEmail,
    deleteMedia,
    getOneMedia,
    getEmail
} from "./endpoints";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { EMAIL_TYPE } from "enums";
import { putSurveyEmail } from "../surveys/endpoints";
import { setEditingSurvey } from "../surveys";
import { getId } from "utils";
import { setMyMediaContent } from "domains/results";
import { uiControlStore } from "store/store";

export const fetchEmail = id => _dispatch => fetchData(getEmail(id));

export const fetchMediaSurvey = id => dispatch =>
    fetchData(getOneMedia(id)).then(res => {
        dispatch(setMyMediaContent(res.content));
    });

export const createMediaThunk = media => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const formData = new FormData();
    formData.append("file", media);
    return fetchFile(createMedia(), formData)
        .then(res => res)
        .catch(() => setSnackbar(true, "Cannot create media", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const deleteMediaThunk = media_id => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(deleteMedia(media_id))
        .then(res => res)
        .catch(() => setSnackbar(true, "Cannot delete media", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const removeAndCreateMedia = (mediaToRemove, mediaToCreate) => dispatch => {
    dispatch(deleteMediaThunk(mediaToRemove));
    return dispatch(createMediaThunk(mediaToCreate));
};

/**
 *  Create a emila media on the server.
 *
 * @param {*} media File media
 * @param {*} code Country or language short code (ex : EN, FR, RO... )
 * @param {*} type Property type "src/enums/{MAIL_PROP_TYPE}"
 * @returns POST return object with the email_file Id.
 */
export const createEmailThunk = (media, code, type) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const formData = new FormData();
    formData.append("file", media);
    formData.append(type, code);

    return fetchFile(createEmail(), formData)
        .then(res => {
            return res;
        })
        .catch(() => setSnackbar(true, "Cannot create email", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const addMailToSurvey = (mail, type) => (dispatch, getState) => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const editing_survey_id = getState().surveys.editing_survey_id;
    const editing_survey = getState().surveys.editing_survey;
    const launchEmail = editing_survey.launchEmail.map(mail => mail["@id"]);
    const triggerEmail = editing_survey.triggerEmail.map(mail => mail["@id"]);

    const new_mail = {};

    switch (type) {
        case EMAIL_TYPE.LAUNCH:
            new_mail.launchEmail = [...launchEmail, mail["@id"]];
            break;
        case EMAIL_TYPE.RECONTACT:
            new_mail.triggerEmail = [...triggerEmail, mail["@id"]];
            break;
        default:
            new_mail.launchEmail = [];
            new_mail.triggerEmail = [];
            break;
    }

    return fetchData(putSurveyEmail(editing_survey_id), new_mail)
        .then(res => {
            const newSurveyEditing = {
                ...editing_survey,
                launchEmail: res.launchEmail,
                triggerEmail: res.triggerEmail
            };
            dispatch(setEditingSurvey(newSurveyEditing));
        })
        .catch(err => setSnackbar(true, "Cannot add email to survey", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const removeMailToSurvey = (mail, type) => async (dispatch, getState) => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const editing_survey_id = getState().surveys.editing_survey_id;
    const editing_survey = getState().surveys.editing_survey;
    const launchEmail = editing_survey.launchEmail.map(mail => mail["@id"]);
    const triggerEmail = editing_survey.triggerEmail.map(mail => mail["@id"]);

    const updated_mail = {};
    switch (type) {
        case EMAIL_TYPE.LAUNCH:
            updated_mail.launchEmail = launchEmail.filter(x => x !== mail["@id"]);
            break;
        case EMAIL_TYPE.RECONTACT:
            updated_mail.triggerEmail = triggerEmail.filter(x => x === mail["@id"]);
            break;
        default:
            updated_mail.launchEmail = [];
            updated_mail.triggerEmail = [];
            break;
    }

    await fetchData(deleteEmail(getId(mail))).catch(err =>
        dispatch(setSnackbar(true, "Cannot delete email ", SNACKBAR_VARIANT_TYPE.ERROR))
    );

    return fetchData(putSurveyEmail(editing_survey_id), updated_mail)
        .then(res => {
            dispatch(setEditingSurvey({ ...editing_survey, res }));
        })
        .catch(err =>
            dispatch(
                setSnackbar(true, "Cannot remove email from survey", SNACKBAR_VARIANT_TYPE.ERROR)
            )
        );
};
