import {
    SET_STEP,
    SET_SUB_STEP,
    SET_WHO_IS_IN_MY_TEAM,
    SET_TOTAL_PERIMETER,
    SET_PROG_N1_MY_TEAM,
    SET_PROG_N1_TOTAL_PERIMETER,
    SET_MY_TEAM_PARAMETER,
    SET_MY_TOTAL_TEAM_PARAMETER,
    SET_LOADER_WHO_IS_IN_MY_TEAM,
    SET_LOADER_TOTAL_PERIMETER,
    SET_LOADER_PROG_N1_MY_TEAM,
    SET_LOADER_PROG_N1_TOTAL_PERIMETER,
    SET_LOADER_UPDATE_LDAP
} from "./types";

const stateInitial = {
    step: 0,
    sub_step: 0,
    who_is_in_your_team: [],
    loader_who_is_in_your_team: false,
    total_perimeter: [],
    loader_total_perimeter: false,
    prog_n1_my_team: [],
    loader_prog_n1_my_team: false,
    prog_n1_total_perimeter: [],
    loader_prog_n1_total_perimeter: false,
    my_team_parameter: [],
    my_total_team_parameter: [],
    loader_update_ldap: false
};

const team = (state = stateInitial, action) => {
    switch (action.type) {
        case SET_STEP:
            return {
                ...state,
                step: action.payload
            };
        case SET_SUB_STEP:
            return {
                ...state,
                sub_step: action.payload
            };
        case SET_WHO_IS_IN_MY_TEAM:
            return {
                ...state,
                who_is_in_your_team: action.payload
            };
        case SET_LOADER_WHO_IS_IN_MY_TEAM:
            return {
                ...state,
                loader_who_is_in_your_team: action.payload
            };
        case SET_LOADER_TOTAL_PERIMETER:
            return {
                ...state,
                loader_total_perimeter: action.payload
            };
        case SET_LOADER_PROG_N1_MY_TEAM:
            return {
                ...state,
                loader_prog_n1_my_team: action.payload
            };
        case SET_LOADER_PROG_N1_TOTAL_PERIMETER:
            return {
                ...state,
                loader_prog_n1_total_perimeter: action.payload
            };
        case SET_TOTAL_PERIMETER:
            return {
                ...state,
                total_perimeter: action.payload
            };
        case SET_PROG_N1_MY_TEAM:
            return {
                ...state,
                prog_n1_my_team: action.payload
            };
        case SET_PROG_N1_TOTAL_PERIMETER:
            return {
                ...state,
                prog_n1_total_perimeter: action.payload
            };
        case SET_MY_TEAM_PARAMETER:
            return {
                ...state,
                my_team_parameter: action.payload
            };
        case SET_MY_TOTAL_TEAM_PARAMETER:
            return {
                ...state,
                my_total_team_parameter: action.payload
            };
        case SET_LOADER_UPDATE_LDAP:
            return {
                ...state,
                loader_update_ldap: action.payload
            };
        default: {
            return state;
        }
    }
};

export default team;
