import { SET_MY_ANSWERS } from "./types";

const stateInitial = [];

const answers = (state = stateInitial, action) => {
    switch (action.type) {
        case SET_MY_ANSWERS:
            return action.payload;
        default: {
            return state;
        }
    }
};

export default answers;
