import { getHeaders, getHeadersXLSX } from "./utils";

const noContent = status => status === 204;
const isSuccessful = status => status >= 200 && status < 300;

const checkStatus = (response, resolve, reject) => {
    if (noContent(response.status)) return response;
    
    return isSuccessful(response.status) ? response.json() : reject(response).statusText;
};

export const fetchData = (endpoint, body = null) => {
    body = body ? JSON.stringify(body) : undefined;
    let httpInfos = {
        method: endpoint.method,
        headers: getHeaders(),
        body: body
    };

    if (process.env.NODE_ENV === "development") {
        console.log(
            httpInfos.method,
            process.env.REACT_APP_API_ENDPOINT + endpoint.suffix,
            httpInfos
        );
    }
    return new Promise((resolve, reject) =>
        fetch(process.env.REACT_APP_API_ENDPOINT + endpoint.suffix, httpInfos)
            .then(res => checkStatus(res, resolve, reject))
            .then(res => {
                if (res !== null) {
                    // SI CA VAUT NULL C'EST QU'UN MAIL EST ENVOyé   AMAZON SES renvoye null quand un mail ets envoyé
                    return res["@type"] === "hydra:Collection"
                        ? resolve(res["hydra:member"])
                        : resolve(res);
                }
            })
            .catch(err => {
                reject(err);
            })
    );
};

export const fetchFile = (endpoint, body) => {
    let httpInfos = {
        method: endpoint.method,
        headers: getHeaders(),
        body: body
    };

    delete httpInfos.headers["Content-Type"];

    if (process.env.NODE_ENV === "development") {
        console.log(
            httpInfos.method,
            process.env.REACT_APP_MEDIA_ENDPOINT + endpoint.suffix,
            httpInfos
        );
    }

    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_ENDPOINT + endpoint.suffix, httpInfos)
            .then(checkStatus)
            .then(res => resolve(res))
            .catch(err => {
                //setSnackbar(true, "An error has occurred", SNACKBAR_VARIANT_TYPE.ERROR)
                reject(err);
            });
    });
};

export const fetchText = (endpoint, body) => {
    let httpInfos = {
        method: endpoint.method,
        headers: getHeadersXLSX(),
        body: body
    };

    if (process.env.NODE_ENV === "development") {
        console.log(
            httpInfos.method,
            process.env.REACT_APP_MEDIA_ENDPOINT + endpoint.suffix,
            httpInfos
        );
    }

    const promise = new Promise((resolve, reject) => {
        // console.log(resolve)
        fetch(process.env.REACT_APP_API_ENDPOINT + endpoint.suffix, httpInfos)
            .then(checkStatus => checkStatus.blob())
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log("err", err);
            });
    });


    return promise;
};

export const fetchForExcel = (endpoint, body) => {
    body = body ? JSON.stringify(body) : undefined;
    let httpInfos = {
        method: endpoint.method,
        headers: getHeaders(),
        body: body
    };

    if (process.env.NODE_ENV === "development") {
        console.log(
            httpInfos.method,
            process.env.REACT_APP_MEDIA_ENDPOINT + endpoint.suffix,
            httpInfos
        );
    }

    const promise = new Promise((resolve, reject) => {
        // console.log(resolve)
        fetch(process.env.REACT_APP_API_ENDPOINT + endpoint.suffix, httpInfos)
            .then(checkStatus => checkStatus.blob())
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                console.log("err", err);
            });
    });


    return promise;
};
