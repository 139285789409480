import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Paper } from "@mui/material";

const PREFIX = "TabsContainer";

const classes = {
    centered: `${PREFIX}Centered`,
    TabsWrapper: `${PREFIX}TabsWrapper`,
    rootTabs: `${PREFIX}RootTabs`,
    rootTab: `${PREFIX}RootTab`
};

const Root = styled("div")(({ _theme }) => ({
    [`& .${classes.centered}`]: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    [`& .${classes.TabsWrapper}`]: {
        margin: "25px 50px",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-evenly",
        minWidth: "500px",
        maxWidth: "750px"
    },
    [`& .${classes.rootTabs}`]: {
        width: "100%"
    },
    [`& .${classes.rootTab}`]: {
        fontWeight: "600",
        flexGrow: 1
    }
}));

// TODO When refactoring a feature using TabsContainer, use TopNavContainer instead (uses react router)
const TabsContainer = props => {
    const { tabs, children } = props;

    const theme = useTheme();

    const initAvailableTab = tabs => {
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].authorized) return i;
        }
        return 0;
    };
    const [value, setValue] = useState(initAvailableTab(tabs));

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <Root>
            <div className={classes.centered}>
                <Paper className={classes.TabsWrapper} variant="outlined">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        classes={{ root: classes.rootTabs }}>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                classes={{ root: classes.rootTab }}
                                disabled={!tab.authorized}
                            />
                        ))}
                    </Tabs>
                </Paper>
            </div>
            <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value}>
                {children}
            </SwipeableViews>
        </Root>
    );
};

export default TabsContainer;
