import { isEmpty } from "lodash";

export const fitFormToReduxForm = (res, is_local = false, country = null) => {
    const { forms } = res;
    let values = {};

    if (!isEmpty(forms)) {
        let form = forms.filter(form => form.isLocal === is_local);

        if (country != null) {
            form = forms.filter(form => form.country === country);
        }

        form.map(form =>
            form.questions.forEach(question => {
                values[question["@id"]] = true;
            })
        );
    }

    return values;
};

export const fitFormToJsonAPI = (is_local = false, questions, country = null, leader = null) => ({
    isLocal: is_local,
    country: country && country.value,
    leader: leader && leader.value,
    language: "en",
    isActive: false,
    questions: questions,
    isFinalized: false
});