import React from "react";
import { Route, Switch } from "react-router-dom";
import * as PAGES from "components/pages";
import * as URLS from "routes/Urls";
import * as ROLES from "enums/Roles";
import { Topbar } from "components/common";
import { Box } from "@mui/material";
import ADMIN_MENU_ITEMS from "enums/AdminMenuItems";
import PrivateRoute from "./PrivateRoute";

const PrivateRoutesList = () => {
    const ADMIN_ROLES = ADMIN_MENU_ITEMS.flatMap(item => item.roles);
    return (
        <Box
            sx={{
                pt: "64px",
                height: "100%",
                boxSizing: "border-box"
            }}>
            <Topbar />
            <Switch>
                <PrivateRoute
                    path={URLS.ADMIN_URL}
                    Component={PAGES.AdminPage}
                    roles={ADMIN_ROLES}
                />
                <PrivateRoute
                    exact
                    path={URLS.CREATE_SURVEY_URL}
                    Component={PAGES.SurveyConceptorPage}
                    roles={[ROLES.SURVEY_CREATE]}
                />
                <PrivateRoute
                    exact
                    path={URLS.EDIT_SURVEY_URL}
                    Component={PAGES.SurveyConceptorPage}
                    roles={[ROLES.SURVEY_MODIFY]}
                />
                <PrivateRoute exact path={URLS.HOME_URL} Component={PAGES.HomePage} />
                <PrivateRoute exact path={URLS.PROFILE_URL} Component={PAGES.MyProfilePage} />
                <PrivateRoute exact path={URLS.MY_SURVEYS_URL} Component={PAGES.MySurveysPage} />
                <PrivateRoute exact path={URLS.MY_TEAM_URL} Component={PAGES.TeamManagerPage} />
                <PrivateRoute
                    exact
                    path={URLS.TEAM_GREATING_URL}
                    Component={PAGES.TeamGreatingPage}
                />
                <PrivateRoute
                    exact
                    path={URLS.MY_RESULTS_URL}
                    Component={PAGES.ResultRefontePage}
                />
                <PrivateRoute
                    exact
                    path={URLS.ANSWER_SURVEY_URL + "/:id"}
                    Component={PAGES.AnswerSurveyPage}
                />
                <PrivateRoute
                    exact
                    path={URLS.PREVIEW_SURVEY_URL + "/:id"}
                    Component={PAGES.AnswerSurveyPage}
                />
                <PrivateRoute
                    exact
                    path={URLS.MY_ANSWERS_URL + "/:id"}
                    Component={PAGES.AnsweredSurveyPage}
                />
                <Route component={PAGES.NotFoundPage} />
            </Switch>
        </Box>
    );
};

export default PrivateRoutesList;
