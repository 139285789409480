import * as TYPES from "./types";

import { findIndex } from "lodash";

const stateInitial = {
    onGoing: [],
    answered: [],
    notAnswered: [],
    surveys_list: [],
    editing_survey_id: null,
    editing_survey: {},
    editingSurveyLoader: false,
    survey_to_fill: {},
    step: 0,
    sub_step: 0,
    current_leader: "",
    editing_survey_leaders: []
};

const surveys = (state = stateInitial, action) => {
    switch (action.type) {
        case TYPES.SET_ONGOING: {
            return {
                ...state,
                onGoing: [...action.payload]
            };
        }
        case TYPES.SET_ANSWERED: {
            return {
                ...state,
                answered: [...action.payload]
            };
        }
        case TYPES.SET_NOT_ANSWERED: {
            return {
                ...state,
                notAnswered: [...action.payload]
            };
        }
        case TYPES.SET_SURVEYS: {
            return {
                ...state,
                surveys_list: [...action.payload]
            };
        }
        case TYPES.ADD_SURVEY: {
            return {
                ...state,
                surveys_list: [...state.surveys_list, action.payload]
            };
        }
        case TYPES.UPDATE_SURVEY: {
            const index = findIndex(state.surveys_list, survey => survey.id === action.payload.id);
            state.surveys_list.splice(index, 1, action.payload);
            return {
                ...state,
                surveys_list: [...state.surveys_list]
            };
        }
        case TYPES.SET_EDIT_SURVEY_ID: {
            return {
                ...state,
                editing_survey_id: action.payload
            };
        }
        case TYPES.SET_EDIT_SURVEY: {
            return {
                ...state,
                editing_survey: { ...action.payload }
            };
        }
        case TYPES.SET_SURVEY_TO_FILL: {
            return {
                ...state,
                survey_to_fill: { ...action.payload }
            };
        }
        case TYPES.SET_STEP:
            return {
                ...state,
                step: action.payload
            };
        case TYPES.SET_CURRENT_LEADER:
            return {
                ...state,
                current_leader: action.payload
            };
        case TYPES.SET_SUB_STEP:
            return {
                ...state,
                sub_step: action.payload
            };
        case TYPES.SET_SURVEY_LEADERS:
            return {
                ...state,
                editing_survey_leaders: action.payload
            };
        case TYPES.SET_EDITING_SURVEY_LOADER:
            return {
                ...state,
                editingSurveyLoader: action.payload
            };
        default: {
            return state;
        }
    }
};

export default surveys;
