import { SET_FORM, ADD_FORM } from "./types";
import { createForm, modifyForm, removeForm, getTargets } from "./endpoints";
import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { fitFormToJsonAPI } from "./formatters";
import { uiControlStore } from "store/store";

export const setForms = forms => ({
    type: SET_FORM,
    payload: forms
});

export const addForm = form => ({
    type: ADD_FORM,
    payload: form
});

export const createUnitedForm = question => async () => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const questions = Object.keys(question).filter(value => question[value] === true);

    try {
        const res = await fetchData(createForm(), fitFormToJsonAPI(false, questions));
        return res;
    } catch {
        return setSnackbar(true, "Cannot create form", SNACKBAR_VARIANT_TYPE.ERROR);
    }
};

export const createLocalForm = (question, country, leader) => async () => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const questions = Object.keys(question).filter(value => question[value] === true);
    try {
        const res = await fetchData(
            createForm(),
            fitFormToJsonAPI(true, questions, country, leader)
        );
        return res;
    } catch {
        return setSnackbar(true, "Cannot create form", SNACKBAR_VARIANT_TYPE.ERROR);
    }
};

export const editUnitedForm = (form, form_id) => async () => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const questions = Object.keys(form).filter(value => form[value] === true);

    try {
        const res = await fetchData(modifyForm(form_id), fitFormToJsonAPI(false, questions));
        return res;
    } catch {
        return setSnackbar(true, "Cannot update form", SNACKBAR_VARIANT_TYPE.ERROR);
    }
};

export const editLocalForm = (form, form_id, country, leader) => async dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const questions = Object.keys(form).filter(value => form[value] === true);

    try {
        const res = await fetchData(
            modifyForm(form_id),
            fitFormToJsonAPI(true, questions, country, leader)
        );
        return res;
    } catch {
        return dispatch(setSnackbar(true, "Cannot update form", SNACKBAR_VARIANT_TYPE.ERROR));
    }
};

export const deleteForm = id => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(removeForm(id))
        .then(res => {})
        .catch(err => {
            setSnackbar(true, "Cannot delete form", SNACKBAR_VARIANT_TYPE.ERROR);
        });
};

export const fetchTargets = keyword => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getTargets(keyword))
        .then(res => [
            {
                label: "UNITED",
                options: [{ value: "UNITED", label: "UNITED", group: "united" }]
            },
            ...res
        ])
        .catch(() => setSnackbar(true, "Cannot fetch targets", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const finalizeForm = form_id => () => fetchData(modifyForm(form_id), { isFinalized: true });
