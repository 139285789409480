import { SNACKBAR_VARIANT_TYPE } from "enums";
import { fetchData } from "api/fetch";
import { getMyProfile } from "../user/endpoints";
import { userProfileStore } from "store/store";

const actions = (set, get) => ({
    setpercentageAnswers: value => {
        set({ percentageAnswers: value });
    },

    setOpenSnackbar: open => {
        set({ openSnackbar: open });
    },

    setMessageSnackbar: message => {
        set({ messageSnackbar: message });
    },

    setSelectedLanguage: selectedLanguage => {
        set({
            languageDirection:
                selectedLanguage === "ar" ||
                selectedLanguage === "ar-ma" ||
                selectedLanguage === "he"
                    ? "rtl"
                    : "ltr",
            selectedLanguage: selectedLanguage
        });
    },

    setSnackbar: (
        open = false,
        message = "An error has occured",
        variant = SNACKBAR_VARIANT_TYPE.ERROR
    ) => {
        set({ variantSnackbar: variant });
        set({ messageSnackbar: message });
        set({ openSnackbar: open });
    },

    fetchPreferredLanguage: () => {
        fetchData(getMyProfile()).then(res => {
            const preferredLanguage = res.preferredLanguage != null ? res.preferredLanguage : "en";
            set({ selectedLanguage: preferredLanguage.toLowerCase() });
        });
    },

    initializeApp: async () => {
        const fetchMyProfile = userProfileStore.getState().fetchMyProfile;
        return fetchMyProfile();
    }
});

export default actions;
