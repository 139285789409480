/**
 * UID of the top level leader.
 * Top level leader can't see his team0.
 * Top level leader can't see his teamN (too much data)
 */
const ULTRA_BOSS_UID = "MABALL50";

/**
 * Despite of the RPGD rules, this application hides the team results if the number of answer can't ensure anonymization.
 */
const MIN_ANSWERS_RULE = 6;

const TEAM = {
    I_AM_IN: "0",
    I_AM_MANAGE: "1",
    TOTAL_PERIMETER: "N"
};

const ANSWER_STEP = {
    DESCRIPTION: 0,
    ANSWER: 1,
    CONFIRMATION: 2,
    GREATINGS: 3,
    LOADER: 4
};

const ANSWER_TYPE = {
    ALL: 99,
    YES_TOTALY: 0,
    YES_RATHER: 1,
    NOT_REALLY: 2,
    NOT_AT_ALL: 3,
    NOT_CONCERNED: 4
};

const COMMON_CORE_FORM_NUMBER = 0;

const FORM_TYPE = {
    create_united: 0,
    edit_united: 1,
    create_local: 2,
    edit_local: 3
};

const SURVEY_CREATION_STEP = {
    information: 0,
    common_core_form: 1,
    summary: 2,
    local_form: 3
};

const SURVEY_STATE = {
    STAND_BY: 0,
    VALIDATED: 1,
    STARTED: 2,
    ENDED: 3
};

const CAMPAIGN_PAGE_MODE = {
    LIST: 0,
    CREATE: 1,
    EDIT: 2
};

const CAMPAIGN_STATE = {
    BEING_CREATED: 0,
    STARTED: 1
};

const TARGET_SURVEY_STATE = {
    STAND_BY: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2
};

const MAIL_PROP_TYPE = {
    LANGUAGE: "language",
    COUNTRY: "country"
};

const EMAIL_TYPE = {
    LAUNCH: 0,
    RECONTACT: 1
};

const AGE_RANGE = {
    BETWEEN_18_AND_24: "Between 18 and 24",
    BETWEEN_25_AND_34: "Between 25 and 34",
    BETWEEN_35_AND_44: "Between 35 and 44",
    BETWEEN_45_AND_54: "Between 45 and 54",
    MORE_THAN_55: " 55 and more "
};

const SENIORITY_RANGE = [
    //{key: 0, label: "Less than 3 months"},
    { value: 0, label: "Between 3 months and 1 year" },
    { value: 1, label: "Between 1 year and 3 years" },
    { value: 2, label: "Between 3 and 5 years" },
    { value: 3, label: "Between 5 and 10 years" },
    { value: 4, label: "More than 10 years" }
];

const IS_PRIVATE = {
    PRIVATE: false
};

const QUESTION_TYPE = {
    FIVE_CHOICES: 5,
    FOUR_CHOICE: 6,
    YES_NO: 7,
    FREE: 8
};

const SEARCH_TYPE = {
    USER: "USER",
    USER_BY_SURVEY: "USER_BY_SURVEY"
};

const SNACKBAR_VARIANT_TYPE = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    SUCCESS: "success"
};

const PROGRESS_TYPE = {
    DAY: "day",
    PERCENTAGE: "percentage"
};

const TRAD_TYPE = {
    DYNAMIC: "dynamic",
    STATIC: "static"
};

export {
    TEAM,
    ULTRA_BOSS_UID,
    MIN_ANSWERS_RULE,
    ANSWER_STEP,
    ANSWER_TYPE,
    COMMON_CORE_FORM_NUMBER,
    FORM_TYPE,
    SURVEY_CREATION_STEP,
    SURVEY_STATE,
    CAMPAIGN_PAGE_MODE,
    CAMPAIGN_STATE,
    TARGET_SURVEY_STATE,
    MAIL_PROP_TYPE,
    EMAIL_TYPE,
    AGE_RANGE,
    SENIORITY_RANGE,
    IS_PRIVATE,
    QUESTION_TYPE,
    SEARCH_TYPE,
    SNACKBAR_VARIANT_TYPE,
    PROGRESS_TYPE,
    TRAD_TYPE
};
