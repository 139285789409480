export const filterSurveysFinishSelector = state => {
    return state.compilations.filterSurveysFinish;
};

export const filterCountrySuggestionSelector = state => {
    return state.compilations.filterCountrySuggestion;
};

export const filterBranchSuggestionSelector = state => {
    return state.compilations.filterBranchSuggestion;
};

export const filterSiteSuggestionSelector = state => {
    return state.compilations.filterSiteSuggestion;
};

export const filterServiceSuggestionSelector = state => {
    return state.compilations.filterServiceSuggestion;
};

export const filterManagerSuggestionSelector = state => {
    return state.compilations.filterManagerSuggestion;
};

export const filterJobNameSuggestionSelector = state => {
    return state.compilations.filterJobNameSuggestion;
};

export const filterCostCenterSuggestionSelector = state => {
    return state.compilations.filterCostCenterSuggestion;
};

export const filterCustomTeamSuggestionSelector = state => {
    return state.compilations.filterCustomTeamSuggestion;
};

export const filterDepartmentSuggestionSelector = state => {
    return state.compilations.filterDepartmentSuggestion;
};

export const filterEcosystemSuggestionSelector = state => {
    return state.compilations.filterEcosystemSuggestion;
};

export const filterSportSuggestionSelector = state => {
    return state.compilations.filterSportSuggestion;
};

export const ifCompilationSelector = state => {
    return state.compilations.ifCompilation;
};

export const ifErrorRuleSelector = state => {
    return state.compilations.ifErrorRule;
};

export const ifCountrySelector = state => {
    return state.compilations.ifCountry;
};

export const ifCalculResultSelector = state => {
    return state.compilations.ifCalculResult;
};

export const resultCountryRateSelector = state => {
    return state.compilations.resultCountryRate;
};

export const resultPleasureSelector = state => {
    return state.compilations.resultPleasure;
};

export const resultThematicSelector = state => {
    return state.compilations.resultThematic;
};

export const resultQuestionSelector = state => {
    return state.compilations.resultQuestion;
};

export const resultFreeQuestionSelector = state => {
    return state.compilations.resultFreeQuestion;
};

export const filterValueForExportSelector = state => {
    return state.compilations.filterValueForExport;
};
