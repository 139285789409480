export const stepSelector = state => state.team.step;
export const subStepSelector = state => state.team.sub_step;
export const whoIsInYourTeamSelector = state => state.team.who_is_in_your_team;
export const totalPerimeterSelector = state => state.team.total_perimeter;
export const progN1MyTeamSelector = state => state.team.prog_n1_my_team;
export const progN1TotalPerimeterSelector = state => state.team.prog_n1_total_perimeter;
export const myTeamParameterSelector = state => state.team.my_team_parameter;
export const myTotalTeamParameterSelector = state => state.team.my_total_team_parameter;
export const loaderWhoIsInYourTeamSelector = state => state.team.loader_who_is_in_your_team;
export const loaderTotalPerimeterSelector = state => state.team.loader_total_perimeter;
export const loaderProgN1MyTeamSelector = state => state.team.loader_prog_n1_my_team;
export const loaderProgN1TotalPerimeterSelector = state =>
    state.team.loader_prog_n1_total_perimeter;
export const loaderUpdateLdapSelector = state => state.team.loader_update_ldap;
