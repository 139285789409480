const prefix = "Teams";

export const SET_STEP = `${prefix}/SET_STEP`;
export const SET_SUB_STEP = `${prefix}/SET_SUB_STEP`;
export const SET_WHO_IS_IN_MY_TEAM = `${prefix}/SET_WHO_IS_IN_MY_TEAM`;
export const SET_TOTAL_PERIMETER = `${prefix}/SET_TOTAL_PERIMETER`;
export const SET_PROG_N1_MY_TEAM = `${prefix}/SET_PROG_N1_MY_TEAM`;
export const SET_PROG_N1_TOTAL_PERIMETER = `${prefix}/SET_PROG_N1_TOTAL_PERIMETER`;
export const SET_MY_TEAM_PARAMETER = `${prefix}/SET_MY_TEAM_PARAMETER`;
export const SET_MY_TOTAL_TEAM_PARAMETER = `${prefix}/SET_MY_TOTAL_TEAM_PARAMETER`;
export const SET_LOADER_WHO_IS_IN_MY_TEAM = `${prefix}/SET_LOADER_WHO_IS_IN_MY_TEAM`;
export const SET_LOADER_TOTAL_PERIMETER = `${prefix}/SET_LOADER_TOTAL_PERIMETER`;
export const SET_LOADER_PROG_N1_MY_TEAM = `${prefix}/SET_LOADER_PROG_N1_MY_TEAM`;
export const SET_LOADER_PROG_N1_TOTAL_PERIMETER = `${prefix}/SET_LOADER_PROG_N1_TOTAL_PERIMETER`;
export const SET_LOADER_UPDATE_LDAP = `${prefix}/SET_LOADER_UPDATE_LDAP`;
