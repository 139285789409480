import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "reducers";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const composeEnhancers = composeWithDevTools({});
export const history = createBrowserHistory();

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        "surveys",
        "survey",
        "countries",
        "translations",
        "languages",
        "profiles",
        "control",
        "users",
        "roles",
        "team"
    ],
    blacklist: ["form", "results", "compilations"]
};

const reducers = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, reducers);

export default function configureStore(preloadedState = {}) {
    const store = createStore(
        persistedReducer, // root reducer with router state
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk
            )
        )
    );

    let persistor = persistStore(store);

    return { store, persistor };
}
