export const getId = object => object["@id"].split("/")[3];

export const mathRound = number => Math.round(number * 100) / 100;

/**
 * This function compute to rate
 * @param {*} usersAnswered
 * @param {*} usersTarget
 * @returns
 */
export const computeRate = (usersAnswered, usersTarget) => {
    const rate = ((usersAnswered / usersTarget) * 100).toFixed(2);
    return parseFloat(rate);
};