import { fetchData } from "api/fetch";
import { push } from "connected-react-router";
import { createLocalForm, createUnitedForm } from "domains/forms";
import { checkPhoto } from "domains/medias/photoCheck";
import { translationsStore, uiControlStore } from "store/store";
import { FORM_TYPE, SNACKBAR_VARIANT_TYPE, SURVEY_CREATION_STEP } from "enums";
import { difference } from "lodash";
import { ANSWER_SURVEY_URL, MY_SURVEYS_URL } from "routes/Urls";
import { createMediaThunk } from "../medias";
import {
    createSurvey,
    getAllLeadersBySurvey,
    getMySurveys,
    getSurveyPreview,
    getSurveyToFill,
    getSurveys,
    putSurveyForm,
    putSurveyGeneralInfo,
    getOneSurveyForms,
    getOneSurveyTemplates,
    getOneSurveyGlobal,
    replaySurvey,
    removeSurvey
} from "./endpoints";
import { fitSurveyToJsonApi, modifySurveyToJsonAPI } from "./formatters";
import * as TYPES from "./types";

export const setStep = step => ({
    type: TYPES.SET_STEP,
    payload: step
});

export const setSubStep = step => ({
    type: TYPES.SET_SUB_STEP,
    payload: step
});

export const addSurvey = survey => ({
    type: TYPES.ADD_SURVEY,
    payload: survey
});

export const setSurveys = surveys => ({
    type: TYPES.SET_SURVEYS,
    payload: surveys
});

export const setEditingSurvey = survey => ({
    type: TYPES.SET_EDIT_SURVEY,
    payload: survey
});

export const setEditingSurveyLoading = bool => ({
    type: TYPES.SET_EDITING_SURVEY_LOADER,
    payload: bool
});

export const setEditingSurveyId = id => ({
    type: TYPES.SET_EDIT_SURVEY_ID,
    payload: id
});

export const updateSurvey = survey => ({
    type: TYPES.UPDATE_SURVEY,
    payload: survey
});

export const setSurveyToFill = survey => ({
    type: TYPES.SET_SURVEY_TO_FILL,
    payload: survey
});

export const setEditingSurveyLeaders = survey => ({
    type: TYPES.SET_SURVEY_LEADERS,
    payload: survey
});

export const setCurrentLeader = leader => ({
    type: TYPES.SET_CURRENT_LEADER,
    payload: leader
});

export const setOnGoingSurveys = surveys => ({
    type: TYPES.SET_ONGOING,
    payload: surveys
});

export const setAnsweredSurveys = surveys => ({
    type: TYPES.SET_ANSWERED,
    payload: surveys
});

export const setNotAnsweredSurveys = surveys => ({
    type: TYPES.SET_NOT_ANSWERED,
    payload: surveys
});

const fetchSurveys = (endpoint, dispatch) => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(endpoint())
        .then(res => dispatch(setSurveys(res)))
        .catch(() => setSnackbar(true, "Cannot fetch surveys", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchAdminSurveys = () => dispatch => fetchSurveys(getSurveys, dispatch);

export const fetchMySurveys = () => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    fetchData(getMySurveys())
        .then(res => {
            const onGoingSurveys = res[0].ongoing;
            const answeredSurveys = res[0].answered.map(survey => {
                return {
                    ...survey.survey,
                    ...{ isReplayable: survey.isReplayable }
                };
            });
            const notAnsweredSurveys = res[0].notAnsweredSurveys.map(survey => {
                return {
                    ...survey.survey,
                    ...{ isReplayable: survey.isReplayable }
                };
            });
            dispatch(setOnGoingSurveys(onGoingSurveys));
            dispatch(setAnsweredSurveys(answeredSurveys));
            dispatch(setNotAnsweredSurveys(notAnsweredSurveys));
        })
        .catch(err => {
            console.error(err);
            setSnackbar(true, "Cannot fetch surveys", SNACKBAR_VARIANT_TYPE.ERROR);
        });
};

export const postReplay = id => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(replaySurvey(id))
        .then(res => {
            dispatch(addSurvey(res));
            dispatch(push(`${ANSWER_SURVEY_URL}/${res.id}`));
        })
        .catch(() =>
            setSnackbar(true, "The survey cannot be replayed!", SNACKBAR_VARIANT_TYPE.ERROR)
        );
};

export const fetchEditingSurvey = id => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    dispatch(setEditingSurveyLoading(true));
    let editingSurvey = {};
    fetchData(getOneSurveyGlobal(id))
        .then(res => {
            editingSurvey = res;
            fetchData(getOneSurveyForms(id))
                .then(formsInfos => {
                    editingSurvey = { ...editingSurvey, ...formsInfos };
                    fetchData(getOneSurveyTemplates(id))
                        .then(templatesInfos => {
                            editingSurvey = { ...editingSurvey, ...templatesInfos };
                            dispatch(setEditingSurvey(editingSurvey));
                            dispatch(setEditingSurveyLoading(false));
                        })
                        .catch(() => {
                            dispatch(setEditingSurveyLoading(false));
                            setSnackbar(
                                true,
                                "Cannot fetch editing survey Templates",
                                SNACKBAR_VARIANT_TYPE.ERROR
                            );
                        });
                })
                .catch(() => {
                    dispatch(setEditingSurveyLoading(false));
                    setSnackbar(
                        true,
                        "Cannot fetch editing survey Forms",
                        SNACKBAR_VARIANT_TYPE.ERROR
                    );
                });
        })
        .catch(() => {
            dispatch(setEditingSurveyLoading(false));
            setSnackbar(true, "Cannot fetch editing survey Global", SNACKBAR_VARIANT_TYPE.ERROR);
        });
};

export const fetchSurveyToFill = (id, is_preview = false) => dispatch => {
    dispatch(setSurveyToFill({}));

    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(is_preview ? getSurveyPreview(id) : getSurveyToFill(id))
        .then(res => {
            if (is_preview) {
                dispatch(setEditingSurvey(res));
            } else {
                dispatch(setSurveyToFill(res));
            }
        })
        .catch(err => {
            if (err.status === 401) {
                setSnackbar(
                    true,
                    "You have already answered the form",
                    SNACKBAR_VARIANT_TYPE.ERROR
                );
                dispatch(push(MY_SURVEYS_URL));
            } else {
                setSnackbar(true, "Cannot get the survey", SNACKBAR_VARIANT_TYPE.ERROR);
            }
        });
};

export const createSurveyThunk = survey => async dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    const createOneTranslation = translationsStore.getState().createOneTranslation;
    const {
        surveyTitle,
        surveyDescription,
        surveyPhoto,
        surveyTargets,
        contractsToExclude
    } = survey;
    const title = await createOneTranslation(surveyTitle, "survey.title", "survey");
    const description = await createOneTranslation(
        surveyDescription,
        "survey.description",
        "survey"
    );

    // photo
    let photo = null;
    if (surveyPhoto != null) {
        photo = await dispatch(createMediaThunk(surveyPhoto));
    }
    // target

    fetchData(
        createSurvey(),
        fitSurveyToJsonApi(survey, title, description, photo, surveyTargets, contractsToExclude)
    )
        .then(value => {
            dispatch(setEditingSurvey(value));
            dispatch(setEditingSurveyId(value["id"]));
            dispatch(setStep(SURVEY_CREATION_STEP.common_core_form));
            dispatch(setSubStep(FORM_TYPE.create_united));
        })
        .catch(() => setSnackbar(true, "Cannot create survey", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const editSurveyThunk = (initial_survey, new_survey) => async dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;
    const editTranslation = translationsStore.getState().editTranslation;
    const { surveyTitle, surveyDescription, surveyTargets, contractsToExclude } = new_survey;

    await editTranslation(initial_survey.title.id, surveyTitle, "en");
    await editTranslation(initial_survey.description.id, surveyDescription, "en");

    // photo
    let photo = new_survey.surveyPhoto;
    const fetchPhoto = checkPhoto(initial_survey, new_survey);
    if (fetchPhoto !== null) photo = await dispatch(fetchPhoto);

    // target
    fetchData(
        putSurveyGeneralInfo(initial_survey.id),
        modifySurveyToJsonAPI(new_survey, photo, surveyTargets, contractsToExclude)
    )
        .then(res => {
            dispatch(updateSurvey({ ...initial_survey, ...res }));
            dispatch(setEditingSurvey({ ...initial_survey, ...res }));
            dispatch(setStep(SURVEY_CREATION_STEP.summary));
        })
        .catch(() => setSnackbar(true, "Cannot update survey", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const addFormToSurvey = (sub_step, values, survey) => async dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    let form = null;
    let res = survey.forms ? survey.forms : [];

    let common_core_survey = survey.forms.filter(f => !f.isLocal)[0];
    let local_surveys = difference(survey.forms, common_core_survey);

    switch (sub_step) {
        case FORM_TYPE.create_united:
            common_core_survey = await dispatch(createUnitedForm(values));
            break;
        case FORM_TYPE.create_local:
            form = await dispatch(createLocalForm(values, values.local_country, values.local_team));
            local_surveys = [...res, form];
            break;
        default:
            break;
    }

    res = [common_core_survey, ...local_surveys];
    res = res.map(r => r["@id"]);

    return fetchData(putSurveyForm(survey.id), { forms: res })
        .then(res => dispatch(setEditingSurvey({ ...survey, ...res })))
        .catch(() => setSnackbar(true, "Cannot update survey", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const deleteSurveyThunk = id => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(removeSurvey(id))
        .then(() => {
            dispatch(setEditingSurveyId(null));
            dispatch(setEditingSurvey(null));
        })
        .catch(() => setSnackbar(true, "There is a problem"));
};

export const fetchAllLeadersBySurvey = id => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    fetchData(getAllLeadersBySurvey(id))
        .then(res => {
            dispatch(
                setEditingSurveyLeaders(
                    res.map(leader => ({
                        value: leader,
                        label: leader
                    }))
                )
            );
        })
        .catch(() => setSnackbar(true, "Leaders can't not collect"));
};
