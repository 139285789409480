import {
    SET_COMPILATIONS_RESULTS,
    SET_SURVEY_FINISH,
    SET_COUNTRY_SUGGESTION,
    SET_SITE_SUGGESTION,
    SET_SERVICE_SUGGESTION,
    SET_MANAGER_SUGGESTION,
    SET_JOBNAME_SUGGESTION,
    SET_BRANCH_SUGGESTION,
    SET_COSTCENTER_SUGGESTION,
    SET_CUSTOMTEAM_SUGGESTION,
    SET_RESULT_PLEASURE,
    SET_RESULT_THEMATIC,
    SET_RESULT_QUESTION,
    SET_RESULT_FREE_QUESTION,
    SET_RESULT_COUNTRY_RATE,
    SET_IF_COMPILATION,
    SET_IF_COUNTRY,
    SET_IF_ERROR_RULE,
    SET_IF_CALCUL_RESULT,
    SET_FILTER_VALUE_FOR_EXPORT,
    SET_DEPARTEMENT_SUGGESTION,
    SET_ECOSYSTEM_SUGGESTION,
    SET_SPORT_SUGGESTION
} from "./type";

const stateInitial = {
    filterSurveysFinish: [],
    filterCountrySuggestion: [],
    filterBranchSuggestion: [],
    filterSiteSuggestion: [],
    filterServiceSuggestion: [],
    filterManagerSuggestion: [],
    filterJobNameSuggestion: [],
    filterCostCenterSuggestion: [],
    filterCustomTeamSuggestion: [],
    filterDepartmentSuggestion: [],
    filterEcosystemSuggestion: [],
    filterSportSuggestion: [],
    resultPleasure: [],
    resultThematic: [],
    resultQuestion: [],
    resultFreeQuestion: [],
    resultCountryRate: [],
    ifCompilation: false,
    ifCountry: true,
    ifErrorRule: false,
    ifCalculResult: false,
    filterValueForExport: []
};

const compilations = (state = stateInitial, action) => {
    switch (action.type) {
        case SET_COMPILATIONS_RESULTS:
            return action.payload;
        case SET_SURVEY_FINISH:
            return {
                ...state,
                filterSurveysFinish: [...action.payload]
            };
        case SET_COUNTRY_SUGGESTION:
            return {
                ...state,
                filterCountrySuggestion: [...action.payload]
            };
        case SET_BRANCH_SUGGESTION:
            return {
                ...state,
                filterBranchSuggestion: [...action.payload]
            };
        case SET_SITE_SUGGESTION:
            return {
                ...state,
                filterSiteSuggestion: [...action.payload]
            };
        case SET_SERVICE_SUGGESTION:
            return {
                ...state,
                filterServiceSuggestion: [...action.payload]
            };
        case SET_MANAGER_SUGGESTION:
            return {
                ...state,
                filterManagerSuggestion: [...action.payload]
            };
        case SET_JOBNAME_SUGGESTION:
            return {
                ...state,
                filterJobNameSuggestion: [...action.payload]
            };
        case SET_COSTCENTER_SUGGESTION:
            return {
                ...state,
                filterCostCenterSuggestion: [...action.payload]
            };
        case SET_CUSTOMTEAM_SUGGESTION:
            return {
                ...state,
                filterCustomTeamSuggestion: [...action.payload]
            };
        case SET_DEPARTEMENT_SUGGESTION:
            return {
                ...state,
                filterDepartmentSuggestion: [...action.payload]
            };
        case SET_ECOSYSTEM_SUGGESTION:
            return {
                ...state,
                filterEcosystemSuggestion: [...action.payload]
            };
        case SET_SPORT_SUGGESTION:
            return {
                ...state,
                filterSportSuggestion: [...action.payload]
            };
        case SET_RESULT_PLEASURE:
            return {
                ...state,
                resultPleasure: [...action.payload]
            };
        case SET_RESULT_THEMATIC:
            return {
                ...state,
                resultThematic: [...action.payload]
            };
        case SET_RESULT_QUESTION:
            return {
                ...state,
                resultQuestion: [...action.payload]
            };
        case SET_RESULT_FREE_QUESTION:
            return {
                ...state,
                resultFreeQuestion: [...action.payload]
            };
        case SET_RESULT_COUNTRY_RATE:
            return {
                ...state,
                resultCountryRate: [...action.payload]
            };
        case SET_IF_COMPILATION:
            return {
                ...state,
                ifCompilation: action.payload
            };
        case SET_IF_COUNTRY:
            return {
                ...state,
                ifCountry: action.payload
            };
        case SET_IF_ERROR_RULE:
            return {
                ...state,
                ifErrorRule: action.payload
            };
        case SET_IF_CALCUL_RESULT:
            return {
                ...state,
                ifCalculResult: action.payload
            };
        case SET_FILTER_VALUE_FOR_EXPORT:
            return {
                ...state,
                filterValueForExport: action.payload
            };
        default:
            return state;
    }
};

export default compilations;
