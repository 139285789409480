import { getMyAnswers } from "./endpoints";
import { fetchData } from "api/fetch";
import { surveyToFillSelector } from "../surveys";
import { fitAnswerToJsonApi } from "./formatters";
import { SET_MY_ANSWERS } from "./types";

export const setMyAnswers = answers => ({
    type: SET_MY_ANSWERS,
    payload: answers
});

export const createMyAnswers = answers => getState => {
    delete answers.all_private;
    const question_ids = Object.keys(answers);

    const objResponse = {
        formResponse: []
    };

    question_ids.forEach(key => {
        const created_answer = fitAnswerToJsonApi(
            answers[key],
            key,
            surveyToFillSelector(getState())["@id"]
        );

        objResponse.formResponse.push(created_answer);
    });
    return objResponse;
};

export const fetchMyAnswers = survey_id => dispatch =>
    fetchData(getMyAnswers(survey_id))
        .then(res => dispatch(setMyAnswers(res)))
        .catch(err => console.log(err));
