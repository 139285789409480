import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import answers from "domains/answers";
import forms from "domains/forms";
import question_types from "domains/questionTypes";
import surveys from "domains/surveys";
import surveysManager from "domains/surveysManager";
import roles from "domains/roles";
import results from "domains/results";
import compilations from "domains/compilations";
import team from "domains/team";
import campaigns from "domains/campaigns";

const createRootReducer = history =>
    combineReducers({
        form: formReducer,
        router: connectRouter(history),
        answers,
        question_types,
        forms,
        roles,
        surveys,
        surveysManager,
        results,
        compilations,
        team,
        campaigns
    });

export default createRootReducer;
