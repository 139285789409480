const prefix = "Surveys";

export const SET_ONGOING = `${prefix}/SET_ONGOING`;
export const SET_ANSWERED = `${prefix}/SET_ANSWERED`;
export const SET_NOT_ANSWERED = `${prefix}/SET_NOT_ANSWERED`;
export const SET_EDITING_SURVEY_LOADER = `${prefix}/SET_EDITING_SURVEY_LOADER`;
export const SET_SURVEYS = `${prefix}/SET_SURVEYS`;
export const SET_EDIT_SURVEY = `${prefix}/SET_EDIT_SURVEY`;
export const UPDATE_SURVEY = `${prefix}/UPDATE_SURVEY`;
export const ADD_SURVEY = `${prefix}/ADD_SURVEY`;
export const SET_EDIT_SURVEY_ID = `${prefix}/SET_EDIT_SURVEY_ID`;
export const ADD_COMMON_CORE = `${prefix}/ADD_COMMON_CORE`;
export const SET_SURVEY_TO_FILL = `${prefix}/SET_SURVEY_TO_FILL`;
export const SET_STEP = `${prefix}/SET_STEP`;
export const SET_SUB_STEP = `${prefix}/SET_SUB_STEP`;
export const SEND_EMAIL = `${prefix}/SEND_EMAIL`;
export const SET_SURVEY_LEADERS = `${prefix}/SET_SURVEY_LEADERS`;
export const SET_CURRENT_LEADER = `${prefix}/SET_CURRENT_LEADER`;
