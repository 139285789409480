export const getMyBloomingResult = id => ({
    method: "GET",
    suffix: `/surveys/my_results?survey=${id}&pleasure=true`
});

export const getMyResultsByTheme = id => ({
    method: "GET",
    suffix: `/surveys/my_results?survey=${id}&thematic=true`
});

export const getMyDetailedResults = id => ({
    method: "GET",
    suffix: `/surveys/my_results?survey=${id}&question=true`
});

export const getMyTeamBloomingResult = (id, team) => ({
    method: "GET",
    suffix: `/surveys/my_team_results?survey=${id}&team=${team}&pleasure=true`
});

export const getMyTeamResultsByTheme = (id, team) => ({
    method: "GET",
    suffix: `/surveys/my_team_results?survey=${id}&thematic=true&team=${team}`
});

export const getMyTeamDetailedResults = (id, team) => ({
    method: "GET",
    suffix: `/surveys/my_team_results?survey=${id}&question=true&team=${team}`
});

export const getMyTeamComments = (id, team) => ({
    method: "GET",
    suffix: `/surveys/my_team_results?survey=${id}&freeQuestion=true&team=${team}`
});

export const getMyCountryBloomingResult = id => ({
    method: "GET",
    suffix: `/surveys/my_country_results?survey=${id}&pleasure=true`
});

export const getMyCountryBloomingUnitedResult = id => ({
    method: "GET",
    suffix: `/surveys/my_country_results?survey=${id}&pleasure=true&united=true`
});

export const getMyCountryResultsByThemeUnited = id => ({
    method: "GET",
    suffix: `/surveys/my_country_results?survey=${id}&thematic=true&united=true`
});

export const getMyCountryDetailedResultsUnited = id => ({
    method: "GET",
    suffix: `/surveys/my_country_results?survey=${id}&question=true&united=true`
});

export const getMyCountryResultsByTheme = id => ({
    method: "GET",
    suffix: `/surveys/my_country_results?survey=${id}&thematic=true`
});

export const getMyCountryDetailedResults = id => ({
    method: "GET",
    suffix: `/surveys/my_country_results?survey=${id}&question=true`
});

export const getMyTeamParticipationRate = (id, team) => ({
    method: "GET",
    suffix: `/surveys/my_team_results?survey=${id}&teamRate=true&team=${team}`
});

export const getMyCountryParticipationRate = id => ({
    method: "GET",
    suffix: `/surveys/my_country_results?survey=${id}&countryRate=true`
});
