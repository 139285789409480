import React from "react";
import { withSuspense } from "components/templates";

const HomePage = withSuspense(React.lazy(() => import("./shared/HomePage")));
const AnswerSurveyPage = withSuspense(React.lazy(() => import("./shared/AnswerSurveyPage")));
const AnsweredSurveyPage = withSuspense(React.lazy(() => import("./shared/AnsweredSurveyPage")));
const SignInPage = withSuspense(React.lazy(() => import("./shared/SignInPage")));
const SignOutPage = withSuspense(React.lazy(() => import("./shared/SignOutPage")));
const MyProfilePage = withSuspense(React.lazy(() => import("./shared/MyProfilePage")));
const MySurveysPage = withSuspense(React.lazy(() => import("./shared/MySurveysPage")));
const ResultRefontePage = withSuspense(React.lazy(() => import("./shared/ResultRefontePage")));
const SurveyConceptorPage = withSuspense(React.lazy(() => import("./admin/SurveyConceptorPage")));
const TeamManagerPage = withSuspense(React.lazy(() => import("./shared/TeamManagerPage")));
const TeamGreatingPage = withSuspense(React.lazy(() => import("./shared/TeamGreatingPage")));
const AdminPage = withSuspense(React.lazy(() => import("./admin/Admin")));
const RightsManagerPage = withSuspense(React.lazy(() => import("./admin/RightsManagerPage")));
const FormsManagerPage = withSuspense(React.lazy(() => import("./admin/FormsManagerPage")));
const SurveysManagerPage = withSuspense(React.lazy(() => import("./admin/SurveysManagerPage")));
const VerbatimPage = withSuspense(React.lazy(() => import("./admin/VerbatimPage")));
const TranslationsManagerPage = withSuspense(
    React.lazy(() => import("./admin/TranslationsManagerPage"))
);
const CompareManagerPage = withSuspense(React.lazy(() => import("./admin/CompareManagerPage")));
const CompilationsPage = withSuspense(React.lazy(() => import("./admin/CompilationsPage")));
const SearchPage = withSuspense(React.lazy(() => import("./admin/SearchPage")));
const CampaignsManagerPage = withSuspense(React.lazy(() => import("./admin/CampaignsManagerPage")));
const LoadingPage = withSuspense(React.lazy(() => import("./common/LoadingPage")));
const ErrorPage = withSuspense(React.lazy(() => import("./common/ErrorPage")));
const ErrorProfilPage = withSuspense(React.lazy(() => import("./common/ErrorProfilPage")));
const NotAllowedPage = withSuspense(React.lazy(() => import("./common/NotAllowedPage")));
const NotFoundPage = withSuspense(React.lazy(() => import("./common/NotFoundPage")));
const MaintenancePage = withSuspense(React.lazy(() => require("./common/MaintenancePage")));

export {
    AdminPage,
    RightsManagerPage,
    FormsManagerPage,
    CompareManagerPage,
    CompilationsPage,
    SurveysManagerPage,
    VerbatimPage,
    AnswerSurveyPage,
    AnsweredSurveyPage,
    HomePage,
    SignInPage,
    SignOutPage,
    MyProfilePage,
    MySurveysPage,
    ResultRefontePage,
    SurveyConceptorPage,
    TeamManagerPage,
    TeamGreatingPage,
    SearchPage,
    CampaignsManagerPage,
    TranslationsManagerPage,
    LoadingPage,
    ErrorPage,
    ErrorProfilPage,
    NotAllowedPage,
    NotFoundPage,
    MaintenancePage
};
