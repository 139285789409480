export const getMyProfile = () => ({
    method: "GET",
    suffix: "/dtb_users/my_profile"
});

export const searchUser = user => ({
    method: "GET",
    suffix: `/dtb_users/search?search=${user}`
});

export const getUser = userUID => ({
    method: "GET",
    suffix: `/dtb_users/${userUID}`
});

export const editUser = id => ({
    method: "PUT",
    suffix: `/dtb_users/${id}`
});

export const extractLdapUsers = date => ({
    method: "GET",
    suffix: `/dtb_users/download_users_extraction?date=${date}`
});
