import { IS_PRIVATE } from "enums";

export const fitAnswerToJsonApi = (answer, generated_question_at_id, survey_at_id) => ({
    generatedQuestion: generated_question_at_id.substring(
        generated_question_at_id.lastIndexOf("/") + 1
    ),
    isPrivate: IS_PRIVATE.PRIVATE,
    score: `${answer.choice}`,
    freeText: answer.free_text != null ? answer.free_text : "",
    survey: survey_at_id.substring(survey_at_id.lastIndexOf("/") + 1),
    form: answer.form.substring(answer.form.lastIndexOf("/") + 1)
});
