import { SET_FORM, ADD_FORM } from "./types";

const stateInitial = [];

const forms = (state = stateInitial, action) => {
    switch (action.type) {
        case SET_FORM: {
            return [...action.payload];
        }
        case ADD_FORM: {
            return [...state, action.payload];
        }
        default: {
            return state;
        }
    }
};

export default forms;
