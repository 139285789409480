import { fetchData } from "api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import { uiControlStore } from "store/store";
import { push } from "connected-react-router";

import {
    SET_COMPILATIONS_RESULTS,
    SET_SURVEY_FINISH,
    SET_COUNTRY_SUGGESTION,
    SET_BRANCH_SUGGESTION,
    SET_SERVICE_SUGGESTION,
    SET_MANAGER_SUGGESTION,
    SET_JOBNAME_SUGGESTION,
    SET_SITE_SUGGESTION,
    SET_COSTCENTER_SUGGESTION,
    SET_CUSTOMTEAM_SUGGESTION,
    SET_RESULT_PLEASURE,
    SET_RESULT_THEMATIC,
    SET_RESULT_QUESTION,
    SET_RESULT_FREE_QUESTION,
    SET_RESULT_COUNTRY_RATE,
    SET_IF_COMPILATION,
    SET_IF_ERROR_RULE,
    SET_IF_CALCUL_RESULT,
    SET_FILTER_VALUE_FOR_EXPORT,
    SET_SPORT_SUGGESTION,
    SET_ECOSYSTEM_SUGGESTION,
    SET_DEPARTEMENT_SUGGESTION
} from "./type";

import {
    getSurveyFinish,
    getCompilationParameters,
    getCompilationResultPleasure,
    getCompilationResultThematic,
    getCompilationResultQuestion,
    getCompilationResultCountryRate,
    getCompilationResultFreeQuestion
} from "./endpoints";

export const setCompilationResults = compilations => ({
    type: SET_COMPILATIONS_RESULTS,
    payload: compilations
});

export const setSurveyFinish = compilations => ({
    type: SET_SURVEY_FINISH,
    payload: compilations
});

export const setCountrySuggestion = compilations => ({
    type: SET_COUNTRY_SUGGESTION,
    payload: compilations
});

export const setBranchSuggestion = compilations => ({
    type: SET_BRANCH_SUGGESTION,
    payload: compilations
});

export const setServiceSuggestion = compilations => ({
    type: SET_SERVICE_SUGGESTION,
    payload: compilations
});

export const setManagerSuggestion = compilations => ({
    type: SET_MANAGER_SUGGESTION,
    payload: compilations
});

export const setJobNameSuggestion = compilations => ({
    type: SET_JOBNAME_SUGGESTION,
    payload: compilations
});

export const setSiteSuggestion = compilations => ({
    type: SET_SITE_SUGGESTION,
    payload: compilations
});

export const setCostCenterSuggestion = compilations => ({
    type: SET_COSTCENTER_SUGGESTION,
    payload: compilations
});

export const setCustomTeamSuggestion = compilations => ({
    type: SET_CUSTOMTEAM_SUGGESTION,
    payload: compilations
});

export const setDepartmentSuggestion = compilations => ({
    type: SET_DEPARTEMENT_SUGGESTION,
    payload: compilations
});

export const setEcosystemSuggestion = compilations => ({
    type: SET_ECOSYSTEM_SUGGESTION,
    payload: compilations
});

export const setSportSuggestion = compilations => ({
    type: SET_SPORT_SUGGESTION,
    payload: compilations
});

export const setResultPleasure = compilations => ({
    type: SET_RESULT_PLEASURE,
    payload: compilations
});

export const setResultThematic = compilations => ({
    type: SET_RESULT_THEMATIC,
    payload: compilations
});

export const setResultQuestion = compilations => ({
    type: SET_RESULT_QUESTION,
    payload: compilations
});

export const setResultFreeQuestion = compilations => ({
    type: SET_RESULT_FREE_QUESTION,
    payload: compilations
});

export const setResultCountryRate = compilations => ({
    type: SET_RESULT_COUNTRY_RATE,
    payload: compilations
});

export const setIfCompilation = compilations => ({
    type: SET_IF_COMPILATION,
    payload: compilations
});

export const setIfErrorRule = compilations => ({
    type: SET_IF_ERROR_RULE,
    payload: compilations
});

export const setIfCalculResult = compilations => ({
    type: SET_IF_CALCUL_RESULT,
    payload: compilations
});

export const setFilterValueForExport = compilations => ({
    type: SET_FILTER_VALUE_FOR_EXPORT,
    payload: compilations
});

export const fetchSurveysFinish = () => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getSurveyFinish())
        .then(res =>
            dispatch(
                setSurveyFinish(
                    res[0]["options"].map(survey => ({
                        value: survey.value,
                        label: survey.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch surveys", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchCountrySuggestion = survey_id => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), { filter: { surveyId: survey_id } })
        .then(res =>
            dispatch(
                setCountrySuggestion(
                    res[0]["options"].map(country => ({
                        value: country.value,
                        label: country.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch country", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchBranchSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getBranches: true }
    })
        .then(res =>
            dispatch(
                setBranchSuggestion(
                    res[0]["options"].map(branch => ({
                        value: branch.value,
                        label: branch.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Branch", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchSiteSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getSites: true }
    })
        .then(res =>
            dispatch(
                setSiteSuggestion(
                    res[0]["options"].map(site => ({
                        value: site.value,
                        label: site.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Site", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchServiceSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getServices: true }
    })
        .then(res =>
            dispatch(
                setServiceSuggestion(
                    res[0]["options"].map(service => ({
                        value: service.value,
                        label: service.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Service", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchManagerSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getLeaders: true }
    })
        .then(res =>
            dispatch(
                setManagerSuggestion(
                    res[0]["options"].map(manager => ({
                        value: manager.value,
                        label: manager.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Service", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchJobNameSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getJobNames: true }
    })
        .then(res =>
            dispatch(
                setJobNameSuggestion(
                    res[0]["options"].map(jobName => ({
                        value: jobName.value,
                        label: jobName.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Service", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchCostCenterSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getCostCenters: true }
    })
        .then(res =>
            dispatch(
                setCostCenterSuggestion(
                    res[0]["options"].map(costCenter => ({
                        value: costCenter.value,
                        label: costCenter.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Service", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchDepartmentSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getDepartments: true }
    })
        .then(res => {
            dispatch(
                setDepartmentSuggestion(
                    res[0]["options"].map(department => ({
                        value: department.value,
                        label: department.label
                    }))
                )
            );
        })
        .catch(() => setSnackbar(true, "Cannot fetch Department", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchEcosystemSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getEcosystems: true }
    })
        .then(res =>
            dispatch(
                setEcosystemSuggestion(
                    res[0]["options"].map(ecosystem => ({
                        value: ecosystem.value,
                        label: ecosystem.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Ecosystem", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchSportSuggestion = (survey_id, countries) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    return fetchData(getCompilationParameters(), {
        filter: { surveyId: survey_id, countries: countries, getSports: true }
    })
        .then(res =>
            dispatch(
                setSportSuggestion(
                    res[0]["options"].map(sport => ({
                        value: sport.value,
                        label: sport.label
                    }))
                )
            )
        )
        .catch(() => setSnackbar(true, "Cannot fetch Sport/Process", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchCompilationResult = filters => (dispatch, getState) => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    setIfErrorRule(false);
    fetchData(getCompilationResultPleasure(), { filters })
        .then(res => {
            dispatch(setResultPleasure(res));
            fetchData(getCompilationResultThematic(), { filters })
                .then(res => {
                    dispatch(setResultThematic(res));
                    fetchData(getCompilationResultQuestion(), { filters })
                        .then(res => {
                            dispatch(setResultQuestion(res));
                            fetchData(getCompilationResultFreeQuestion(), { filters })
                                .then(res => {
                                    dispatch(setResultFreeQuestion(res));
                                    fetchData(getCompilationResultCountryRate(), { filters })
                                        .then(res => {
                                            dispatch(setResultCountryRate(res));
                                            dispatch(setIfCompilation(true));
                                        })
                                        .catch(err => {
                                            console.log(err);
                                        });
                                })
                                .catch(err => {
                                    console.log(err);
                                    dispatch(push("/error"));
                                });
                        })
                        .catch(err => {
                            console.log(err);
                            dispatch(push("/error"));
                        });
                })
                .catch(err => {
                    console.log(err);
                    dispatch(push("/error"));
                });
        })
        .catch(res => {
            if (res.status == undefined) {
                setSnackbar(true, "Cannot fetch result", SNACKBAR_VARIANT_TYPE.ERROR);
                dispatch(setIfCalculResult(false));
            } else {
                dispatch(setIfErrorRule(true));
            }
        });
};
