const LOCALSTORAGE_TOKEN_KEY = process.env.REACT_APP_TOKEN_NAME;
const getAccessToken = () => localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);

export const getHeaders = () => ({
    "Content-Type": "application/ld+json",
    Authorization: `Bearer ${getAccessToken()}`,
    Origin: process.env.REACT_APP_DOMAIN_URL
});

export const getHeadersXLSX = () => ({
    "Content-Type": "application/file",
    Authorization: `Bearer ${getAccessToken()}`,
    Origin: process.env.REACT_APP_DOMAIN_URL
});

export const REQUEST_STATUS = {
    PENDING: "pending",
    OK: "ok",
    ERROR: "error",
    LOADING: "loading",
    IDLE: "idle"
};
