const prefix = "Compilations";

export const SET_COMPILATIONS_RESULTS = `${prefix}/SET_COMPILATIONS_RESULTS`;
export const SET_SURVEY_FINISH = `${prefix}/SET_SURVEY_FINISH`;
export const SET_COUNTRY_SUGGESTION = `${prefix}/SET_COUNTRY_SUGGESTION`;
export const SET_BRANCH_SUGGESTION = `${prefix}/SET_BRANCH_SUGGESTION`;
export const SET_SITE_SUGGESTION = `${prefix}/SET_SITE_SUGGESTION`;
export const SET_SERVICE_SUGGESTION = `${prefix}/SET_SERVICE_SUGGESTION`;
export const SET_MANAGER_SUGGESTION = `${prefix}/SET_MANAGER_SUGGESTION`;
export const SET_JOBNAME_SUGGESTION = `${prefix}/SET_JOBNAME_SUGGESTION`;
export const SET_COSTCENTER_SUGGESTION = `${prefix}/SET_COSTCENTER_SUGGESTION`;
export const SET_CUSTOMTEAM_SUGGESTION = `${prefix}/SET_CUSTOMTEAM_SUGGESTION`;
export const SET_DEPARTEMENT_SUGGESTION = `${prefix}/SET_DEPARTEMENT_SUGGESTION`;
export const SET_ECOSYSTEM_SUGGESTION = `${prefix}/SET_ECOSYSTEM_SUGGESTION`;
export const SET_SPORT_SUGGESTION = `${prefix}/SET_SPORT_SUGGESTION`;
export const SET_RESULT_PLEASURE = `${prefix}/SET_RESULT_PLEASURE`;
export const SET_RESULT_THEMATIC = `${prefix}/SET_RESULT_THEMATIC`;
export const SET_RESULT_QUESTION = `${prefix}/SET_RESULT_QUESTION`;
export const SET_RESULT_FREE_QUESTION = `${prefix}/SET_RESULT_FREE_QUESTION`;
export const SET_RESULT_COUNTRY_RATE = `${prefix}/SET_RESULT_COUNTRY_RATE`;
export const SET_IF_COMPILATION = `${prefix}/SET_IF_COMPILATION`;
export const SET_IF_COUNTRY = `${prefix}/SET_IF_COUNTRY_COMPILATION`;
export const SET_IF_ERROR_RULE = `${prefix}/SET_IF_ERROR_RULE`;
export const SET_IF_CALCUL_RESULT = `${prefix}/SET_IF_CALCUL_RESULT`;
export const SET_FILTER_VALUE_FOR_EXPORT = `${prefix}/SET_FILTER_VALUE_FOR_EXPORT`;
