import { createMediaThunk, deleteMediaThunk, removeAndCreateMedia } from "./actions";

export const checkPhoto = (initialValues, submitValues) => {
    if (initialValues.media === null) {
        if (oneNewMedia(initialValues, submitValues))
            return createMediaThunk(submitValues.surveyPhoto);
        if (noMedia(initialValues, submitValues)) {
            return null;
        }
    } else {
        if (removeMedia(initialValues, submitValues))
            return deleteMediaThunk(initialValues.media.id);
        if (sameMedia(initialValues, submitValues)) return null;
        if (editMedia(initialValues, submitValues))
            return removeAndCreateMedia(initialValues.media.id, submitValues.surveyPhoto);
    }
};

const oneNewMedia = (initialValuesPart, submitValues) =>
    initialValuesPart.media === null && submitValues.surveyPhoto !== null;
const removeMedia = (initialValuesPart, submitValues) =>
    initialValuesPart.media !== null && submitValues.surveyPhoto === null;
const noMedia = (initialValues, submitValues) =>
    initialValues.media === null && submitValues.surveyPhoto === null;
const sameMedia = (initialValues, submitValues) =>
    initialValues.media["@id"] === submitValues.surveyPhoto["@id"];
const editMedia = (initialValuesPart, submitValues) =>
    initialValuesPart.media !== null && submitValues.surveyPhoto !== null;
