import {
    SET_LOADER_PROG_N1_MY_TEAM,
    SET_LOADER_PROG_N1_TOTAL_PERIMETER,
    SET_LOADER_TOTAL_PERIMETER,
    SET_LOADER_UPDATE_LDAP,
    SET_LOADER_WHO_IS_IN_MY_TEAM,
    SET_MY_TEAM_PARAMETER,
    SET_MY_TOTAL_TEAM_PARAMETER,
    SET_PROG_N1_MY_TEAM,
    SET_PROG_N1_TOTAL_PERIMETER,
    SET_STEP,
    SET_SUB_STEP,
    SET_TOTAL_PERIMETER,
    SET_WHO_IS_IN_MY_TEAM
} from "./types";
import { fetchData } from "../../api/fetch";
import { SNACKBAR_VARIANT_TYPE } from "enums";
import {
    createTeamParameter,
    updateTeamParameter,
    getCheckTotalPerimeter,
    getCheckWhoIsInYourTeam,
    getMyTeamParameter,
    getProgN1WithTypeAndTitle,
    postUpdateMyTeamFromLdap
} from "./endpoints";
import { push } from "connected-react-router";
import { TEAM_GREATING_URL } from "../../routes/Urls";
import { uiControlStore } from "store/store";

export const setStep = step => ({
    type: SET_STEP,
    payload: step
});

export const setSubStep = step => ({
    type: SET_SUB_STEP,
    payload: step
});

export const setWhoIsInYourTeam = teamArray => ({
    type: SET_WHO_IS_IN_MY_TEAM,
    payload: teamArray
});

export const setLoaderWhoIsInYourTeam = teamArray => ({
    type: SET_LOADER_WHO_IS_IN_MY_TEAM,
    payload: teamArray
});

export const setTotalPerimeter = teamArray => ({
    type: SET_TOTAL_PERIMETER,
    payload: teamArray
});

export const setLoaderTotalPerimeter = teamArray => ({
    type: SET_LOADER_TOTAL_PERIMETER,
    payload: teamArray
});

export const setProgN1MyTeam = teamArray => ({
    type: SET_PROG_N1_MY_TEAM,
    payload: teamArray
});

export const setLoaderProgN1MyTeam = teamArray => ({
    type: SET_LOADER_PROG_N1_MY_TEAM,
    payload: teamArray
});

export const setProgN1TotalPerimeter = teamArray => ({
    type: SET_PROG_N1_TOTAL_PERIMETER,
    payload: teamArray
});

export const setLoaderProgN1TotalPerimeter = teamArray => ({
    type: SET_LOADER_PROG_N1_TOTAL_PERIMETER,
    payload: teamArray
});

export const setMyTeamParameter = teamArray => ({
    type: SET_MY_TEAM_PARAMETER,
    payload: teamArray
});

export const setMyTotalTeamParameter = teamArray => ({
    type: SET_MY_TOTAL_TEAM_PARAMETER,
    payload: teamArray
});

export const setLoaderUpdateLdap = teamArray => ({
    type: SET_LOADER_UPDATE_LDAP,
    payload: teamArray
});

export const fetchProgN1Options = (title, type) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    fetchData(getProgN1WithTypeAndTitle(title, type, "search"))
        .then(res => {
            let arrayTeam = [];
            let resSize = 0;
            res.forEach(team => {
                arrayTeam.push({ value: team.id, label: team.title });
                resSize++;
                if (resSize === res.length) {
                    type === "total_perimeter"
                        ? dispatch(setProgN1TotalPerimeter(arrayTeam))
                        : dispatch(setProgN1MyTeam(arrayTeam));
                }
            });
        })
        .catch(() => setSnackbar(true, "Cannot find progN1 Team", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchWhoIsInYourTeam = () => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    dispatch(setLoaderWhoIsInYourTeam(true));
    fetchData(getCheckWhoIsInYourTeam())
        .then(res => {
            dispatch(setLoaderWhoIsInYourTeam(false));
            dispatch(setWhoIsInYourTeam(res));
        })
        .catch(() => setSnackbar(true, "Cannot find your team", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchTotalPerimeter = () => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    dispatch(setLoaderTotalPerimeter(true));
    fetchData(getCheckTotalPerimeter())
        .then(res => {
            dispatch(setLoaderTotalPerimeter(false));
            dispatch(setTotalPerimeter(res));
        })
        .catch(() => setSnackbar(true, "Cannot find your team", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const updateMyTeamFromLdap = (totalPerimeter, bossUid) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    dispatch(setLoaderUpdateLdap(true));
    fetchData(postUpdateMyTeamFromLdap(), { managerUid: bossUid })
        .then(() => {
            setSnackbar(
                true,
                "Your team are updated, wait for refresh",
                SNACKBAR_VARIANT_TYPE.SUCCESS
            );
            dispatch(setLoaderUpdateLdap(false));
            if (totalPerimeter) {
                dispatch(fetchTotalPerimeter());
            } else {
                dispatch(fetchWhoIsInYourTeam());
            }
            dispatch(fetchMyTeamParameter(bossUid, "total_perimeter"));
            dispatch(fetchMyTeamParameter(bossUid, "direct_team"));
        })
        .catch(() =>
            dispatch(setSnackbar(true, "Cannot update your team", SNACKBAR_VARIANT_TYPE.ERROR))
        );
};

export const fetchMyTeamParameter = (bossUid, type) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    fetchData(getMyTeamParameter(bossUid, type))
        .then(res => {
            if (0 in res) {
                type === "total_perimeter"
                    ? dispatch(setMyTotalTeamParameter(res[0]))
                    : dispatch(setMyTeamParameter(res[0]));
            } else {
                type === "total_perimeter"
                    ? dispatch(setMyTotalTeamParameter(res))
                    : dispatch(setMyTeamParameter(res));
            }
        })
        .catch(() => setSnackbar(true, "Cannot find your team", SNACKBAR_VARIANT_TYPE.ERROR));
};

export const fetchCreateTeamParameter = (myTeam, myTotalTeam) => dispatch => {
    const setSnackbar = uiControlStore.getState().setSnackbar;

    setSnackbar(true, "Please wait...", SNACKBAR_VARIANT_TYPE.WARNING);
    if (myTotalTeam != null) {
        if (myTotalTeam.hasOwnProperty("id")) {
            fetchData(updateTeamParameter(myTotalTeam.id), myTotalTeam)
                .then(res => {})
                .catch(err => {
                    setSnackbar(
                        true,
                        "Cannot create your total team, contact the support",
                        SNACKBAR_VARIANT_TYPE.ERROR
                    );
                });
        } else {
            fetchData(createTeamParameter(), myTotalTeam)
                .then(res => {})
                .catch(err => {
                    console.log(err);
                    setSnackbar(
                        true,
                        "Cannot create your total team, contact the support",
                        SNACKBAR_VARIANT_TYPE.ERROR
                    );
                });
        }
    }
    if (myTeam.hasOwnProperty("id")) {
        fetchData(updateTeamParameter(myTeam.id), myTeam)
            .then(res => {
                setSnackbar(true, "Team created !", SNACKBAR_VARIANT_TYPE.SUCCESS);
                dispatch(push(TEAM_GREATING_URL));
            })
            .catch(err => {
                setSnackbar(
                    true,
                    "Cannot create your team, contact the support",
                    SNACKBAR_VARIANT_TYPE.ERROR
                );
            });
    } else {
        fetchData(createTeamParameter(), myTeam)
            .then(res => {
                setSnackbar(true, "Team created !", SNACKBAR_VARIANT_TYPE.SUCCESS);
                dispatch(push(TEAM_GREATING_URL));
            })
            .catch(err => {
                console.log(err);
                setSnackbar(
                    true,
                    "Cannot create your team, contact the support",
                    SNACKBAR_VARIANT_TYPE.ERROR
                );
            });
    }
};
