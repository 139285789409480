import { createSelector } from "reselect";
import { SURVEY_STATE } from "enums";
import { filter } from "lodash";

export const surveysSelector = state => state.surveys.surveys_list;
export const surveyToFillSelector = state => state.surveys.survey_to_fill;
export const editingSurveySelector = state => state.surveys.editing_survey;
export const stepSelector = state => state.surveys.step;
export const subStepSelector = state => state.surveys.sub_step;
export const currentLeaderSelector = state => state.surveys.current_leader;
export const editingSurveyIdSelector = state => state.surveys.editing_survey_id;
export const editingSurveyLoaderSelector = state => state.surveys.editingSurveyLoader;

export const doneSurveysSelector = createSelector([surveysSelector], surveys =>
    filter(surveys, survey => survey.status === SURVEY_STATE.ENDED)
);

export const myOnGoingSurveysSelector = state => state.surveys.onGoing || [];

export const myAnsweredSurveysSelector = state => state.surveys.answered || [];

export const myNotAnsweredSurveysSelector = state => state.surveys.notAnswered || [];

export const leadersTargetSurveySelector = state => {
    return state.surveys.editing_survey_leaders;
};
